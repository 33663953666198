import React, {memo, FunctionComponent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { ButtonBase, CircularProgress, Dialog } from '../../../../lib/components';
import { Case } from '../../../../lib/interfaces/case.interface';
import EditPatientForm from '../../../../lib/components/EditPatientForm';
import { PatientForm } from '../../../../lib/interfaces/patient-form.interface';
import { caseService, messageService } from '../../../../services';

interface Props {
  patientCase: Case;
}

const EditPatientButton: FunctionComponent<Props> = memo(({ patientCase }) => {
  const { t } = useTranslation();
  const toggleDialog = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submit = (values: PatientForm, event: () => void) => {
    toggleDialog();
    setLoading(true);
    caseService.updateCase(patientCase.patientId, values).subscribe(
      () => {
        messageService.addMessage(t('text.patientUpdated', {id: patientCase.patientId}));
        setLoading(false);
      },
      () => setLoading(false)
    )
  }

  return (
    <>
      {
        isLoading ? 
          <CircularProgress size={16} />
        :
          <ButtonBase onClick={toggleDialog}>
            <EditIcon />
          </ButtonBase>
      }
      
      <Dialog
        open={open}
        setOpen={setOpen}
        title={t('header.editPatient', {id: patientCase.patientId})}
        onCancel={toggleDialog}
        noButtons
      >
        <EditPatientForm patientCase={patientCase} submitForm={submit} onCancel={toggleDialog} />
      </Dialog>
    </>
  );
});

export default EditPatientButton;
