import { Case } from './../interfaces/case.interface';

export interface CaseViewModel {
  patientName: string;
  patientFirstName: string; 
  patientLastName: string;
  patientId: string;
  deviceId: string;
  deviceType: string;
  sex: string;
  age: number;
  initiationDate: string;
}

export function caseViewModel({
  patientFirstName, patientLastName, patientId, deviceId, deviceType, sex, age,
  initiationDate
}: Case): CaseViewModel {
  return {
    patientFirstName, 
    patientLastName,
    patientName: `${patientFirstName} ${patientLastName}`,
    patientId,
    deviceId,
    deviceType,
    sex,
    age,
    initiationDate
  }

}
