import React, { memo, FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, CircularProgress } from '@material-ui/core';
import { UrgentMode } from '../../../../lib/constants/urgent-mode.enum';
import { deviceService } from '../../../../services';
// import UamToggle from "../UamToggle";
import {Button, Dialog} from "../../../../lib/components";
import { useTranslation } from "react-i18next";
import iconSettings from "../../../../theme/icon_setting.png";
import UrgentModeForm from "./UrgentModeForm";
// import {colors} from "../../../../theme/colors";
import { FrequencyReportInterface } from "../../../../lib/interfaces/frequency-report.interface";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      position: 'relative',
    },
    item: {
      width: 166,
      height: 42,
      padding: '5px 10px',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      border: '1px solid #E0E0E0',
      cursor: 'pointer',
    },
    secondItem: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    titleText: {
      fontSize: 11,
      color: '#404040',
      marginTop: 0,
      marginBottom: 0,
      fontWeight: 600,
    },
    smallText: {
      fontSize: 11,
      color: '#404040',
      marginTop: 0,
      marginBottom: 0,
    },
    activeBlock: {
      border: '1px solid #E0E0E0',
      backgroundColor: '#5C6AA9',
    },
    activeText: {
      color: '#fff'
    },
    iconBlock: {
      marginLeft: 7,
      marginTop: 7,
      cursor: 'pointer',
    },
    iconImg: {
      width: 30,
      height: 30,
    },
    pendingBlock: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: -6,
      background: 'rgba(255, 255, 255, .8)'
    },
    pendingCircle: {
      color: '#2CABE1',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -32,
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: "center"
    },
    errorText: {
      fontSize: 14,
      color: 'rgba(0, 0, 0, 0.54)',
      cursor: 'pointer',
      textDecoration: 'none',
      marginBottom: 20,
    },
    blueText: {
      color: '#45AFE3',
    },
    linkedBtn: {

    }
  })
);

interface Props {
  deviceId: string;
  caseId: string;
  urgentAttentionMode: boolean;
  normalModeMinutes: number | null;
  pendingStatus: string | null;
  errorField: string | null;
}

const UrgentModeComponent: FunctionComponent<Props> = memo(({
  deviceId, urgentAttentionMode, caseId, normalModeMinutes, pendingStatus, errorField
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(false);
  const [modalReport, setModalReport] = useState<boolean>(false);
  const [urgentMode, setUrgentMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(!!errorField);
  const styles = useStyles();

  /*const onUamSubmit = (isUrgentAttentionModeActive: boolean) => {
    toggleMode(isUrgentAttentionModeActive, deviceId, caseId);
  };*/

  const changeUrgentMod = (urgentModeKey: boolean) => {
    if (urgentModeKey !== urgentMode) {
      setUrgentMode((urgentMode) => !urgentMode);
      deviceService.switchSensorMode(deviceId, urgentAttentionMode ? UrgentMode.NORMAL : UrgentMode.URGENT_ATTENTION).subscribe({
        next: () => {
          setUrgentMode((urgentMode) => !urgentMode);
          setModal((modal) => !modal)
        }
      });
    }
  };

  const changeFrequencyReport = () => {
    setModalReport((report) => !report);
  };

  const submitForm = (value: FrequencyReportInterface, onComplete: () => void) => {
    const numberWithoutDot = value.minutes.includes('.') ? value.minutes.slice(0, -1) : value.minutes;
    deviceService.changeNormalReportingMode(deviceId, +numberWithoutDot).subscribe({
      next: () => {
        onComplete();
        setModalReport(false);
        setModal(true);
      },
      error: (error) => {
        onComplete();
        console.log(error);
      }
    })
  };

  const deleteError = () => deviceService.deleteErrorFromDevice(deviceId).subscribe(() => setErrorMessage(false));

  useEffect(() => {
    setUrgentMode(urgentAttentionMode)
  }, [urgentAttentionMode]);

  useEffect(() => {
    setErrorMessage(!!errorField)
  }, [errorField])

  return (
    <div className={styles.container}>
      <div className={`${styles.item} ${urgentMode && styles.activeBlock}`} onClick={() => changeUrgentMod(true)} onKeyPress={() => {}}>
        <p className={`${styles.titleText} ${urgentMode && styles.activeText}`}>Enhanced Reporting mode</p>
        <p className={`${styles.smallText} ${urgentMode && styles.activeText}`}>Every 1 min</p>
      </div>
      <div className={`${styles.item} ${styles.secondItem} ${!urgentMode && styles.activeBlock}`} onClick={() => changeUrgentMod(false)} onKeyPress={() => {}}>
        <p className={`${styles.titleText} ${!urgentMode && styles.activeText}`}>Normal Reporting mode</p>
        <p className={`${styles.smallText} ${!urgentMode && styles.activeText}`}>Every {typeof normalModeMinutes === 'number' ? normalModeMinutes : 5} min</p>
      </div>
      <div className={styles.iconBlock} onClick={() => changeFrequencyReport()} onKeyPress={() => {}}>
        <img className={styles.iconImg} src={iconSettings} alt="icon_settings"/>
      </div>
      <Dialog
        open={modal}
        setOpen={setModal}
        onCancel={() => setModal(false)}
        noButtons
        okButton={true}
        btnText={t('button.gotIt')}
        smallStyle={true}
        contentText={`Please wait. It may take up to ${typeof normalModeMinutes === 'number' ? normalModeMinutes * 2 : 5} minutes to change the mode. Once done, the mode will be changed automatically.`}
      />
      <Dialog
        open={modalReport}
        setOpen={setModalReport}
        onCancel={() => setModalReport(false)}
        title={'Normal Reporting mode configuration'}
        noButtons
        smallStyle={true}
      >
        <UrgentModeForm submitForm={submitForm} closeForm={() => setModalReport(false)}/>
      </Dialog>
      <Dialog
        open={errorMessage}
        setOpen={setErrorMessage}
        noButtons
        smallStyle={true}
        onCancel={deleteError}
      >
        <div className={styles.errorContainer}>
          <span
            className={styles.errorText}
          >{errorField}</span>
          <Button
            onClick={deleteError}
          >
            { t('button.gotIt') }
          </Button>
        </div>
      </Dialog>
      {
        pendingStatus && <div className={styles.pendingBlock}>
          <CircularProgress size={24} className={styles.pendingCircle} />
        </div>
      }
    </div>
  );
});

export default UrgentModeComponent;
