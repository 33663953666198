import { DATE_FORMAT } from './../constants/date-format.constant';
import { AggregateBy } from './../constants/aggregation.enum';
import moment, { DurationInputArg2 } from 'moment';

export function dateTimeFormat(date: string|null): string|null {
  return date ? moment(date).format('MMM DD YYYY HH:mm') : null;
}

export function getDateFrom(amount: number, unit: DurationInputArg2): string {
  return moment().subtract(amount, unit).format('YYYY-MM-DDTHH:mm:ss[Z]');
}

export function extractTime(date: string): string {
  return moment(date).format('HH:mm');
}

export function extractDate(date: string): string {
  return moment(date).format('MM.DD.YYYY');
}

export function extractDateAggregation(date: string) {
  const dateDif = Math.ceil(moment().diff(date, 'days', true));
  if (dateDif > 1) {
    return (timestamp: string) => String(moment(timestamp).startOf('day').format());
  }
  return (timestamp: string) => String(moment(timestamp).startOf('hour').format());
}

export function extractAggregatedDate(aggregationType: AggregateBy|string): 
  (timestamp: string) => string {
  switch (aggregationType) {
    case AggregateBy.MINUTES:
      return (timestamp: string): string => String(moment(timestamp, DATE_FORMAT).startOf('minute').format());
      case AggregateBy.DAYS:
        return (timestamp: string) => String(moment(timestamp, DATE_FORMAT).startOf('day').format());
      case AggregateBy.HOURS:
      default:
      return (timestamp: string) => String(moment(timestamp, DATE_FORMAT).startOf('hour').format());
  }
}
