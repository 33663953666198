import React, {memo, FunctionComponent, useState, useEffect,} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import HistroryTable from './components/HistroryTable';
import HistoryChart from './components/HistoryChart';
// import HistoryFilter from './components/HistoryFilter';
import { Case } from '../../lib/interfaces/case.interface';
import moment from 'moment';
import { MetricOrder } from '../../lib/constants/metric-order.enum';
import { MeasurementKeys } from '../../lib/interfaces/measurement-keys.type';
import { DATE_FORMAT } from '../../lib/constants/date-format.constant';
import useAggregatedMetrics from '../../hooks/useAggregatedMetrics';
import {isSimplifiedHistory} from "../../lib/utils/is-simplified-history";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      height: '100%',
      width: '100%',
      display: 'flex',
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 32,
      overflowY: 'auto'
    },
    table: {
      marginLeft: 16,
      width: '30%',
      position: 'relative'
    },
    rightWrapper: {
      width: '70%',
      display: 'flex',
      flexFlow: 'column'
    },
    filter: {
      display: 'flex',
      width: '100%',
      marginBottom: 16,
    },
    chart: {
      display: 'flex',
      width: '100%',
      flex: 1,
    }
  })
);

interface Props {
  patientCase: Case;
  fieldName: MeasurementKeys|null;
  filter: any;
  chosenElement: number;
  lastHour: boolean;
  setGraphClick: (value: boolean) => void;
  setButtonDisable: (value: boolean) => void;
}

const History: FunctionComponent<Props> = memo(({ fieldName, patientCase, filter, chosenElement, setGraphClick, setButtonDisable, lastHour }) => {
  const styles = useStyles({fieldName});
  const [hrField, SetHrField] = useState<boolean>(false);
  const [tableView, setTableView] = useState<boolean>(false);
  const [data, setNewData] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNewData(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, [])

  useEffect( () => {
    let localData = localStorage.getItem('tableView');
    if (!localData) {
      localStorage.setItem('tableView', `${tableView}`);
    } else {
      setTableView(localData === 'true');
    }
  }, [tableView]);

  useEffect(() => {
    SetHrField(fieldName === 'hr');
    setNewData(new Date());
  }, [fieldName]);

  const metrics = useAggregatedMetrics(
    fieldName, patientCase.patientId, patientCase.caseId,
    patientCase.chestSize, {
      from: filter, to: moment.utc().format(DATE_FORMAT),
      sortOrder: MetricOrder.DESC
    }, data, lastHour
  );

  const hrMetrics = useAggregatedMetrics(
    "hr", patientCase.patientId, patientCase.caseId,
    patientCase.chestSize, {
      from: filter, to: moment.utc().format(DATE_FORMAT),
      sortOrder: MetricOrder.DESC
    }, data, lastHour
  );

  const saveTableView = (item: boolean) => {
    localStorage.setItem('tableView', `${item}`);
    setTableView(item);
  };

  return (
    <div className={styles.container}>
      <div className={styles.rightWrapper} style={{width: !tableView || isSimplifiedHistory(fieldName) ? '100%' : '70%'}}>
        <div className={styles.chart}>
          <HistoryChart
            keyName={fieldName}
            metrics={metrics}
            hrMetrics={hrMetrics}
            hrField={hrField}
            setGraphClick={setGraphClick}
            setButtonDisable={setButtonDisable}
            tableView={tableView}
            setTableView={saveTableView}
            lastHour={lastHour}
          />
        </div>
      </div>
      {isSimplifiedHistory(fieldName) ? null : (
        <div className={styles.table} style={{display: !tableView ? 'none' : 'block'}}>
          <HistroryTable fieldName={fieldName} metrics={metrics} chosenElement={chosenElement} setTableView={saveTableView} />
        </div>
      )}
    </div>
  );
});

export default History;
