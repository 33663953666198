import { HttpOptions } from './../interfaces/http-options';
import { Observable, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { switchMap, map, catchError } from 'rxjs/operators';
import { authService } from '../../services';
import { urlCreator } from '../utils/url-creator';

const DOMAIN = process.env.REACT_APP_API_DOMAIN;

const config = (token: string, path: string, urlParams?: {[key: string]: string}) => ({
  url: urlCreator(`${DOMAIN}${path}`, urlParams),
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
    // 'Content-Security-Policy': 'default-src https:; script-src https: \'unsafe-inline\'; style-src https: \'unsafe-inline\'; img-src *;'
  },
  crossDomain: true,
  createXHR: function () {
    return new XMLHttpRequest();
  }
});

export const Api = {
  get: (path: string, options?: HttpOptions): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({...config(token, path, options?.urlParams)})
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },

  post: (path: string, body: any, options?: any): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({
          ...config(token, path),
          method: 'POST',
          body
        })
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },

  patch: (path: string, body: any, options?: any): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({
          ...config(token, path),
          method: 'PATCH',
          body
        })
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },

  delete: (path: string, options?: HttpOptions): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({
          ...config(token, path, options?.urlParams),
          method: 'DELETE',
        })
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },
};
