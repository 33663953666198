import { delay, map, mergeMap, repeat } from 'rxjs/operators';
import { Case } from '../../lib/interfaces/case.interface';
import { useState, useEffect } from 'react';
import { caseService } from '../../services';
import { of } from "rxjs";
import { toMinutes } from "../../lib/utils/to-minutes";

const useCurrentCase = (patientId: string|null) => {
  const [patientCase, setPatientCase] = useState<Case|null>(null);

  useEffect(() => {
    const sub = of({}).pipe(
      mergeMap(() => caseService.cases.pipe(
        map(cases => {
          const foundCase = (cases || []).find(patientCase => patientCase.patientId === patientId);
          setPatientCase(foundCase || null);
        }),
      )),
      delay(toMinutes(1)),
      repeat(),
    ).subscribe();

    return () => sub.unsubscribe();
  }, [patientId]);

  return patientCase;
};

export default useCurrentCase;
