import React, {memo, FunctionComponent} from 'react';
import { VariableSizeList as List } from 'react-window';

interface Props {
  height: number;
  itemCount: number;
  itemSize: (index: number) => number;
  width: number;
  overscanCount?: number;
}

const FixedSizeList: FunctionComponent<Props> = memo(({ 
  height, itemCount, itemSize, width, overscanCount, children
}) => {
  return (
    <List
      height={height}
      itemCount={itemCount}
      itemSize={itemSize}
      width={width}
      overscanCount={overscanCount}
    >
      {/* {children} */}
    </List>
  );
});

export default FixedSizeList;
