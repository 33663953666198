import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {colors} from "../../../../theme/colors";
import React, {FunctionComponent, memo} from "react";
import {ButtonBase, Typography} from "../../../../lib/components";
import Paper from "../../../../lib/components/Paper";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      marginRight: 8,
      marginLeft: 8,
    },
    item: {
      position: 'relative',
      width: 146,
      height: 105,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'left',
      border: `2px solid ${colors.exhibitionTileBorder}`,
      backgroundColor: colors.exhibitionTileBackgroundBlack,
      display: 'flex',
      justifyContent: 'center'
    },
    chosen: {
      backgroundColor: colors.exhibitionTileBackgroundBlack,
      border: `2px solid ${theme.palette.primary.main}`
    },
    bigPadding: {
      paddingBottom: 16
    },
    itemWarning: {
      border: `2px solid ${theme.palette.error.main}`
    },
    chosenWarning: {
      backgroundColor: theme.palette.error.light,
    },
    errorIcon: {
      position: 'absolute',
      top: -10,
      left: -10,
      fontSize: 16
    },
    displayError: {
      color: theme.palette.error.main
    },
    title: {
      fontSize: 10
    },
    data: {
      display: 'flex',
      justifyContent: 'center'
    },
    dataItem: {
      fontWeight: 700,
      fontSize: 45,
      lineHeight: '53px'
    },
    dataItemSmall: {
      fontSize: 24,
      lineHeight: '28px'
    },
    dataExtension: {
      paddingLeft: 5
    },
    iconBlock: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 15
    },
    iconImg: {
      maxWidth: 85,
      maxHeight: 50,
      marginTop: 7,
    },
    iconContainer: {
      marginLeft: 2,
      marginTop: 0
    },
    tooltipText: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      paddingBottom: 1
    },
    tooltip: {
      width: 280,
    }
  })
);

interface Props {
  text: string;
  data: string | null
}

const DataItem: FunctionComponent<Props> = memo(({text, data}) => {
  const styles = useStyles();

  return (
    <ButtonBase className={styles.container}>
      <Paper className={
        `${styles.item}`
      }
             elevation={1}
      >

        <div>
          <Typography className={styles.title}>{text}</Typography>

          <div>
            <div className={styles.data}>
              <Typography className={styles.dataItem}>{data}</Typography>
            </div>
          </div>
        </div>

      </Paper>
    </ButtonBase>
  );
});

export default DataItem;


