import { AggregateBy } from './../constants/aggregation.enum';
import moment from 'moment';
import { extractAggregatedDate } from './format-date';

export function generateTimeRange(from: string, to?: string): string[] {
    const fromMoment = moment(from)
    const toMoment = moment(to);
    const dateDif = Math.ceil(toMoment.diff(fromMoment, 'days', true));
    let newValue = fromMoment;
    const result = [] as string[];
  
    while (toMoment.diff(newValue, dateDif > 1 ? 'day' : 'hour', true) >= 0) {
      if (dateDif > 1) {
        result.push(newValue.startOf('day').format());
        newValue = newValue.add(1, 'day');
      } else {
        result.push(newValue.startOf('hour').format());
        newValue = newValue.add(1, 'hour');
      }
    }
    return result;
}

export function generateAggregatedTimeRange(
  aggregateBy: AggregateBy|string, from: string, to: string
): string[] {
  const fromMoment = moment(from)
  const toMoment = moment(to);

  let newValue = fromMoment;
  const aggregationDate = extractAggregatedDate(aggregateBy);
  const result = [aggregationDate(newValue.format())] as string[];
  const unitValue = aggregateBy === AggregateBy.HOURS ? 'hour' : 'minute'

  while (toMoment.diff(newValue, unitValue, true) > 0) {
    newValue = newValue.add(1, unitValue);
    result.push(aggregationDate(newValue.format()));
  }
  return result;
}
