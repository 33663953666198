import React, {memo, FunctionComponent} from 'react';
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';

interface Props {
  batteryValue: number;
}

const substractBatteryValue = (value: any): number => {
  const batteryLevel = value ? Math.floor((Number(value) / 10)) * 10 : 0;
  if (batteryLevel >= 100) {
    return 100;
  } else {
    return batteryLevel;
  }
};

const BatteryIcons: FunctionComponent<Props> = memo(({ batteryValue }) => {
  const recalculated = substractBatteryValue(batteryValue)
  switch(recalculated) {
    case 100:
      return <BatteryFullIcon color="primary" />
    case 90:
      return <Battery90Icon color="primary" />;
    case 80:
      return <Battery80Icon color="primary" />;
    case 70:
    case 60:
      return <Battery60Icon color="primary" />;
    case 50:
      return <Battery50Icon color="primary" />;
    case 40:
    case 30:
      return <Battery30Icon color="primary" />;
    case 20:
      return <Battery20Icon color="primary" />;
    case 10:
    default:
      return <BatteryAlertIcon color="error" />;
  }
});

export default BatteryIcons;
