import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';
import useDevices from '../../hooks/useDevices';
import NoDevicesComponent from './components/NoDevicesComponent';
// import DeviceAddNew from './components/DeviceAddNew';
import { CircularProgress, CommonHeader } from '../../lib/components';
import DeviceList from './components/DeviceList';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minHeight: '100vh',
      width: '100%',
      backgroundColor: colors.backgroundBlue,
      paddingLeft: 280,
    },
    body: {
      paddingLeft: 52,
      paddingTop: 10,
      paddingBottom: 40
    }
  })
);

const ManageDevices: FunctionComponent = memo(() => {
  const styles = useStyles();
  const devices = useDevices();

  return (
    <>
      <CommonHeader title={'manageDevices'} shouldGoBack />
      <div className={styles.container}>
        <div className={styles.body}>
          
          {
            devices ?
              <>
                {/*<DeviceAddNew />*/}
                {
                  devices.length ? 
                    <DeviceList devices={devices} />
                  :
                    <NoDevicesComponent />
                }
              </>
              :
              <CircularProgress />
          }

        </div>
      </div>
    </>
  );
});

export default ManageDevices;
