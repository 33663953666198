import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Typography from '../Typography';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginRight: 50,
      
    },
    label: {
      fontSize: 14
    },
    data: {
      marginBottom: 17,
      fontSize: 16
    }
  })
);


interface Props {
  dataText?: string|null;
  labelText?: string;
}

const InformationItem: FunctionComponent<Props> = memo(({
  labelText, dataText
}) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography className={styles.label}>{labelText}</Typography>
      <Typography className={styles.data}>{dataText}</Typography>
    </div>
  );
});

export default InformationItem;
