import React from 'react';
import Amplify from 'aws-amplify';
import { AWSConfig } from './lib/config/aws-exports';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import { ErrorDisplay, MessageDisplay } from './lib/components';
import { useError, useMessage } from './hooks';
import MainNavigator from './navigator/MainNavigator';

// * Amplify configuration
Amplify.configure(AWSConfig);

function App() {
  const [error, setError] = useError();
  const [message, setMessage] = useMessage();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <CssBaseline />
      <ErrorDisplay error={error} setError={setError} />
      <MessageDisplay message={message} setMessage={setMessage} />
      <MainNavigator />
    </MuiPickersUtilsProvider>
  );
}

export default App;
