export const messageForGraphTooltip = (message: string | null) => {
  const concatMessage = message && message.split(".")[0];
  if (concatMessage) {
    switch (concatMessage) {
      case 'The measurement is outside the expected range':
        return 'out-of-range data';
      case 'The device is not being worn':
        return 'not worn';
      default:
        return 'no data';
    }
  } else {
    return '';
  }
}