import { MeasurementKeys } from '../interfaces/measurement-keys.type';
import { AdditionalMeasurementData } from '../interfaces/measurement-view.interface';
import { isNullable } from './checkValue';
import { getActivityFactor } from './get-activity-factor';
import { getChestExpansion } from './getChestExpansion';

export function valueHandler(key: MeasurementKeys, value: null|number|boolean, additionalData?: AdditionalMeasurementData): any {
  if (value === null) {
    return null;
  }
  switch(key) {
    case 'p':
      return value < 30 ? 'position.laying' : 'position.sitting';
    case 'af2':
      return !isNullable(value) ? getActivityFactor(Number(value)) : null;
    case 'ce':
      return getChestExpansion(Number(value), additionalData)
    default:
      return value;
  }
}

export function valueIconHandler(key: MeasurementKeys, value: null|number|boolean): string {
  switch(key) {
    default:
      return '';
  }
} 
