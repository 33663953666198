import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '../Typography';
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#E1ECF9'
    },
    actions: {
      display: 'flex',
      alignItems: 'center'
    },
    container: {
      height: 76,
      display: 'flex',
      justifyContent: 'space-between'
    },
    title: {
      fontWeight: 'bold'
    },
    iconContainer: {
      marginLeft: 10,
      marginTop: 3,
    },
    tooltipText: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
    },
    boldText: {
      fontWeight: 700,
    }
  })
);

interface Props {
  title: string;
  utility?: React.ReactNode;
  actionItem?: React.ReactNode;
}

const Header: FunctionComponent<Props> = memo(({ title, utility, actionItem}) => {
  const styles = useStyles();

  return (
    <AppBar classes={{root: styles.root}} elevation={0} position="sticky" color='default'>
      <Toolbar className={styles.container}>
        <div className={styles.actions}>
          {actionItem}
          <Typography variant="h6" className={styles.title}>
            { title }
          </Typography>
          {
            title === 'Manage Devices' ? <div
              className={styles.iconContainer}
              data-tip
              data-for='global'
            >
                <InfoOutlinedIcon/>
            </div> : null
          }
          <ReactTooltip id='global' place='right' backgroundColor='#FBFCFD' effect='solid' type="light">
            <p className={styles.tooltipText}>To manage all your hardware, please download the </p>
            <p className={styles.tooltipText}><span className={styles.boldText}>TEKTELIC eDoctor</span> mobile application.</p>
          </ReactTooltip>
        </div>
        
        { utility }
      </Toolbar>
    </AppBar>
  );
});

export default Header;
