import moment from 'moment';

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export function sortByDate<T>(fieldName?: keyof T, asc?: boolean, dateFormat?: string) {
  return function(array: T[]) {
    return (array && array.length) ? 
      array.sort((a: T, b: T) => {
        const aDate = moment(fieldName ? a[fieldName] : a, dateFormat);
        const bDate = moment(fieldName ? b[fieldName] : b, dateFormat);
        return asc ? aDate.diff(bDate) : bDate.diff(aDate);
      }) 
    :
      [];
  }
}
