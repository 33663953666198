import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../../../lib/components';
import { colors } from '../../../../theme/colors';
import { AggregateBy } from '../../../../lib/constants/aggregation.enum';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      paddingTop: 24,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      position: 'sticky',
      top: 0,
      backgroundColor: '#ffffff',
      zIndex: 100,
    },
    leftItem: {
      width: '35%'
    },
    rightItem: {
      width: '65%'
    },
    subhead: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    text: {
      fontSize: 14,
      color: colors.textGray
    },
    subHeadValue: {
      marginRight: 15,
      width: 53,
      "&:last-child": {
        marginRight: 0
      }
    },
    closeBtn: {
      position: 'absolute',
      top: 10,
      right: 10,
      color: '#000000',
      cursor: 'pointer',
    }
  })
);

interface Props {
  fieldName: string;
  aggregateBy: AggregateBy|string;
  chosenElement: number;
  setTableView: (value: boolean) => void;
}

const HistoryTableHeader: FunctionComponent<Props> = memo(({ fieldName, aggregateBy, chosenElement, setTableView }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftItem}>
        <Typography className={styles.text}>{t('header.time')}</Typography>
      </div>
      <div className={styles.rightItem}>
        <Typography className={styles.text}>{t(`healthData.${fieldName}`)} {t(`${chosenElement === 1 ? `healthData.perHour` : ``}`)}</Typography>
        
        {
          aggregateBy !== AggregateBy.MINUTES &&
          <div className={styles.subhead}>
            <Typography className={`${styles.text} ${styles.subHeadValue}`}>{t('label.min')}</Typography>
            <Typography className={`${styles.text} ${styles.subHeadValue}`}>{t('label.avg')}</Typography>
            <Typography className={`${styles.text} ${styles.subHeadValue}`}>{t('label.max')}</Typography>
          </div>
        }

        <div className={styles.closeBtn} onClick={() => setTableView(false)}>X</div>
      </div>
    </div>
  );
});

export default HistoryTableHeader;
