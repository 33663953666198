import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Header, ButtonBase } from '../../../../lib/components';
import GetAppIcon from '@material-ui/icons/GetApp';
import MemoryIcon from '@material-ui/icons/Memory';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../../../lib/constants/nav-route.constant';
import csvService from '../../../../services/csvService';
import { downloadCSV } from '../../../../lib/utils/download-csv';
import CircularProgressWithLabel from '../../../../lib/components/CircularProgressWithLabel';
import { LOADERT_CSV_MAIN } from '../../../../lib/constants/loader-name.constant';
import usePatientCaseLoader from '../../../../hooks/usePatientCaseLoader';

const useStyles = makeStyles(() =>
  createStyles({
    utils: {
      display: 'flex',
      alignItems: 'center'
    },
    utilityButtons: {
      padding: 5,
      borderRadius: '50%',
      marginLeft: 10, 
    },
    progress: {
      padding: 5,
      marginLeft: 10,
    },
  })
);

const navigateToManageDevices = (history: any) => () => {
  history.push(NavRoute.MAIN.MANAGE_DEVICES);
}

const EmployeeHeader: FunctionComponent = memo(() => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const csvLoader = usePatientCaseLoader();

  const downloadCSVForExport = () => csvService.getAlCasesData(t).subscribe({
    next: (data) => {downloadCSV(`Export of all cases`, data)},
    complete: csvService.resetLoader
  })

  return (
    <Header 
      title={t('header.patientListPage')}
      utility={
        <div className={styles.utils}>
          <ButtonBase className={styles.utilityButtons} onClick={navigateToManageDevices(history)}>
            <MemoryIcon />
          </ButtonBase>

          {/* <ButtonBase className={styles.utilityButtons} onClick={() => {}}>
            <FilterListIcon />
          </ButtonBase> */}

        {
          csvLoader && csvLoader.name === LOADERT_CSV_MAIN ?
            <div className={styles.progress}>
              <CircularProgressWithLabel size={40} value={Number(csvLoader.value)} />
            </div>
          :
            <ButtonBase className={styles.utilityButtons}
              onClick={downloadCSVForExport}
            >
              <GetAppIcon />
            </ButtonBase>
        }
        </div>
      }
    />
  );
});

export default EmployeeHeader;
