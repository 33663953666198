import { CHEST_SIZE } from './../../lib/constants/chest-size.constant';
import { Case } from './../../lib/interfaces/case.interface';
import { ValueExtension } from './../../lib/constants/value-extension.constant';
import { TFunction } from 'i18next';
import { MetricNameMap } from './../../lib/constants/metrics-name.enum';
import { MetricHistoryItem } from './../../lib/interfaces/metric-history.interface';
import { concatMap, reduce, retry, map, tap, switchMap, take, mergeMap } from 'rxjs/operators';
import { MeasurementKeys } from './../../lib/interfaces/measurement-keys.type';
import metricService from '../metricService';
import { from , Subject } from 'rxjs';
import { rollup } from 'd3';
import moment from 'moment';
import { EXPORT_DATE_FORMAT } from '../../lib/constants/date-format.constant';
import caseService from '../caseService';
import { LoadingState } from '../../lib/interfaces/loading-state.interface';
import { LOADERT_CSV_MAIN } from '../../lib/constants/loader-name.constant';
import { EMPTY_READING } from '../../lib/constants/empty-reading.constant';
import { sortByDate } from '../../lib/utils/stable-sort';
import { getChestExpansion } from '../../lib/utils/getChestExpansion';
import { getActivityFactor } from '../../lib/utils/get-activity-factor';

interface MetricType extends MetricHistoryItem {
  fieldName: string;
}

const measuresForDisplay: Array<MeasurementKeys> = ['bt', 'hr', 'rr', 'ce', 'p', 'af2'];

interface MetricData {
  fieldName: MeasurementKeys;
  data: MetricHistoryItem[];
}

const transformData = ({ data, fieldName }: MetricData) => data.map(item => ({ ...item, fieldName }));
const reduceMeasures = <T extends MetricType>(acc: T[], val: T[]) => acc.concat(val);
const reduceRollups = (acc: {[key: string]: any}, val: MetricType) => ({ ...acc, [val.fieldName]: val.value});
const rollupData = <T extends MetricType>(data: T[]) => 
  rollup(data, v => v.reduce(reduceRollups, {}), d => d.timestamp);

const CsvService = () => {
  const patientCaseState = new Subject<LoadingState|null>();

  const getCaseData = (patientCase: Case, t: TFunction, skipLoader?: boolean) => {
    const {
      patientId, caseId, patientFirstName, patientLastName, chestSize,
      initiationDate, deviceId, deviceName
    } = patientCase;

    return from(measuresForDisplay).pipe(
      mergeMap((fieldName: MeasurementKeys) => metricService.getAllHistory(MetricNameMap[fieldName], patientId, caseId, {}).pipe(
        tap(() => {
          if (!skipLoader) {
            const index = measuresForDisplay.findIndex(item => item === fieldName);
            patientCaseState.next({name: patientId, value: Math.round(((index + 1) / measuresForDisplay.length) * 100)});
          }
        }),
        map((data: MetricHistoryItem[]): MetricData => ({fieldName, data} as MetricData)),
        retry(1)
      )),
      reduce((acc: MetricData[], val: MetricData) => acc.concat(val), []),
      map((measures: MetricData[]) => rollupData(measures.map(transformData).reduce(reduceMeasures, []))),
      map(data => {
        const keys = Array.from(data.keys());
        const values = Array.from(data.values());
        const fieldName = t('csvExport.dateTime') as string;
        return sortByDate<any>(fieldName, false, EXPORT_DATE_FORMAT)(keys.map((dateTime: any, i) => ({
          [t('csvExport.dateTime')]: moment.utc(dateTime).format(EXPORT_DATE_FORMAT),
          [t('csvExport.sensorId')]: deviceId,
          [t('csvExport.sensorName')]: deviceName,
          [t('csvExport.patientId')]: patientId,
          [t('csvExport.firstName')]: patientFirstName,
          [t('csvExport.lastName')]: patientLastName,
          [t('csvExport.dateCreated')]: moment(initiationDate).format(EXPORT_DATE_FORMAT),
          [`${t('csvExport.chestSize')} (${ValueExtension['ce']})`]: chestSize || CHEST_SIZE,
          [`${t('csvExport.bt')} (\u00B0C)`]: values[i]['bt'] || EMPTY_READING,
          [`${t('csvExport.hr')} (${ValueExtension['hr']})`]: values[i]['hr'] || EMPTY_READING,
          [`${t('csvExport.rr')} (${ValueExtension['rr']})`]: values[i]['rr'] || EMPTY_READING,
          [`${t('csvExport.ce')} (${ValueExtension['ce']})`]: (values[i]['ce'] || values[i]['ce'] === 0) ?
             getChestExpansion(values[i]['ce'], {chestSize}) : EMPTY_READING,
          [`${t('csvExport.p')} (\u00B0)`]: values[i]['p'] || EMPTY_READING,
          [t('csvExport.af2')]: values[i]['af2'] ? t(`${getActivityFactor(Number(values[i]['af2']))}`) : EMPTY_READING,
        })));
      }),
    ) 
  }

  const getAlCasesData = (t: TFunction) => {
    return caseService.getCasesList().pipe(
      take(1),
      switchMap((patientCases: Case[]) => {
        return from(patientCases).pipe(
          concatMap((patientCase: Case) => getCaseData(patientCase, t, true).pipe(
            tap(() => {
              const index = patientCases.findIndex(item => item.patientId === patientCase.patientId);
              patientCaseState.next({name: LOADERT_CSV_MAIN, value: Math.round(((index + 1) / patientCases.length) * 100)});
            }),
          )),
          reduce((acc: any[], val: any) => acc.concat(val), []),
        )
      }),
      
    )
  };

  const resetLoader = () => patientCaseState.next(null);

  return {
    patientCaseState: patientCaseState.asObservable(),
    getAlCasesData,
    resetLoader,
    getCaseData
  }
};

const singleton = CsvService();
export default Object.freeze(singleton);
