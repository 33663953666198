import { MetricNameSignChars } from './../../lib/constants/metrics-name.enum';
import { AggregateBy } from './../../lib/constants/aggregation.enum';
import { MeasurementKeys } from '../../lib/interfaces/measurement-keys.type';
import { MetricNameMap } from '../../lib/constants/metrics-name.enum';
import { take, map, switchMap, tap } from 'rxjs/operators';
import { UIMetricOptions, MetricHistoryItem, MetricsView } from '../../lib/interfaces/metric-history.interface';
import { useState, useEffect } from 'react';
import { metricService, loaderService } from '../../services';
import { LoaderName } from '../../lib/constants/loader-name.constant';
import { extractAggregatedDate } from '../../lib/utils/format-date';
import { generateAggregatedTimeRange } from '../../lib/utils/generate-time-range';
import { rollup, max as d3max, min as d3min, median } from 'd3';
import moment from 'moment';
import { getChestExpansion } from "../../lib/utils/getChestExpansion";

interface Data extends MetricHistoryItem {
  aggregatingTime: string;
}

interface DataItem {
  min: number;
  max: number;
  maxView: number;
  aggregatedTime: string;
  message: string[] | null;
  previousDataDate: string | null;
}

const keyListResolver = (key: string): [string, {aggregatedTime: string}] => 
  ([key, {aggregatedTime: key}]);

const useAggregatedMetrics = (
  fieldName: MeasurementKeys|null, patientId: string,
  caseId: string, chestSize: number|undefined,  options: UIMetricOptions, data: any, lastHour: boolean
) => {
  const [metrics, setMetrics] = useState<MetricsView[]|null>(null);
  const [localCaseId, setLocalCaseId] = useState<String>(caseId);

  useEffect(() => {
    if (fieldName) {
      loaderService.enable(`${LoaderName.METRICS}`);

      const sub = metricService.getAllHistory(MetricNameMap[fieldName], patientId, caseId, options).pipe(
        tap(() => {
          const diff = moment(options.to).diff(options.from, 'days');
          // MD-353: preserve zoom level when user selects other measurement type of the same patient
          if (localCaseId !== caseId) {
            setLocalCaseId(caseId);
            !lastHour && metricService.resetAggregation(diff > 1 ? AggregateBy.DAYS : AggregateBy.HOURS);
          }
        }),
        take(1),
        switchMap(
          (metrics) => metricService.aggregationState.pipe(
            map((config) => {
              const aggregator = extractAggregatedDate(config.aggregateBy);
              const extendedMetrics = metrics.map(item => {
                return {
                  ...item,
                  value: fieldName === 'ce' ? +getChestExpansion(+item.value, {chestSize}) : item.value,
                  aggregatingTime: aggregator(item.timestamp)
                }
              })

              const rollupData = rollup(extendedMetrics, (leaf: Data[]) => {
                const min = d3min(leaf, (item: any) => item.value);
                const max = d3max(leaf, (item: any) => item.value);
                const minBorder = min + 0.15 * (max - min);
                const maxBorder = max - 0.15 * (max - min);
                const avgFiltered = leaf.filter(item => Number(item.value) >= minBorder && Number(item.value) <= maxBorder);
                const avg = median(avgFiltered, d => Number(d.value)) || min;
                const diffAvg = avg ? avg - min : 0;
                const diffMax = avg ? max - avg : max - min;
                return ({
                  min, max, avg: Number(avg.toFixed(MetricNameSignChars[fieldName])), maxView: diffMax === 0 ? 1 : diffMax,
                  avgView: diffAvg, 
                  aggregatedTime: leaf[0].aggregatingTime,
                  message: leaf[0].message,
                  previousDataDate: leaf[0].previousDataDate
                })
              }, k => k.aggregatingTime);

              const keys = generateAggregatedTimeRange(
                config.aggregateBy,
                config.from || options.from,
                config.to || String(options.to)
              )

              const keysMap = new Map(keys.map(keyListResolver));

              return (Array.from(keysMap.values()) as DataItem[]).map((item: any) => {
                if (rollupData.has(item.aggregatedTime)) {
                  return rollupData.get(item.aggregatedTime);
                }
                return item;
              });
            })
          )
        ),
      ).subscribe({
        next: (metrics) => {
          setMetrics(metrics);
          loaderService.disable();
        },
        complete: () => loaderService.disable()
      });
      
      return () => sub.unsubscribe();
    }

   // eslint-disable-next-line
  }, [options.from, fieldName, patientId, data]);

  return metrics;
};

export default useAggregatedMetrics;
