export const ValueExtension: {[key: string]: string} = {
  'bs': '%',
  'bt': `${String.fromCharCode(0x00B0)}C`,
  'hr': 'bpm',
  'hrv': 'ms',
  'spo2': '%',
  'rr': 'bpm',
  'ce': 'cm',
  'p': '',
  'af': '',
  'af2': '',
  'P_CSV': String.fromCharCode(0x00B0)
}
