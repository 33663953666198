import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { PrivateRoute } from '../lib/components';
import { NavRoute } from '../lib/constants/nav-route.constant';
import { 
  ForgotPassword, Main, ManageDevices, ResetPassword,
  SignIn, SignInSubmit, NotFound, CreatePatientCase
} from '../pages';

export default function MainNavigator() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={NavRoute.MAIN.INIT} />
        </Route>

        <PrivateRoute path={NavRoute.MAIN.INIT}>
          <Main />
        </PrivateRoute>

        <PrivateRoute path={NavRoute.MAIN.MANAGE_DEVICES}>
          <ManageDevices />
        </PrivateRoute>

        <PrivateRoute path={NavRoute.MAIN.CREATE}>
          <CreatePatientCase />
        </PrivateRoute>

        <Route path={NavRoute.AUTH.SIGN_IN}>
          <SignIn />
        </Route>
        <Route path={NavRoute.AUTH.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route path={NavRoute.AUTH.RESET_PASSWORD}>
          <ResetPassword />
        </Route>
        <Route path={NavRoute.AUTH.CONFIRM_PASSWORD}>
          <SignInSubmit />
        </Route>



        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
};
