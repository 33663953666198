export const colors = {
  blue: '#5A69AE',
  secondaryBlue: '#62AEDF',
  blueDisabled: '#E1ECF9',
  black: '#404040',
  gray: '#9EA2A5',
  lightGray: '#9EA2A5',
  textGray: '#7E7E7E',
  backgroundBlue: '#F0F2F7',
  lightBlue: '#F2F4FF',
  backgroundGray: '#F8F8F8',
  borderBlue: '#F0F2F7',
  red: '#E26653',
  lightRed: '#FFF9F8',
  green: '#54BC57',
  white: '#ffffff',
  exhibitionBackgroundBlack: '#121212',
  exhibitionTileBackgroundBlack: '#1E1E1E',
  exhibitionGraphTextColorGray: '#A0A0A0',
  exhibitionTileBorder: '#404040',
  exhibitionTableViewButton: '#00A1E6',
};

