import React, {memo, FunctionComponent, useEffect, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Select } from '../../../../lib/components';
import { deviceService } from '../../../../services';
import { Device } from '../../../../lib/interfaces/device.interface';
import { SelectInterface } from '../../../../lib/interfaces/select-elements.interface';
import { map } from 'rxjs/operators';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex'
    }
  })
);


interface Props {
  onChange: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const SelectDeviceComponent: FunctionComponent<Props> = memo(({
  error, disabled, required, onChange, helperText
}) => {
  const styles = useStyles();
  const [elements, seElements] = useState<SelectInterface[]|null>()
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    onChange(value);
    setValue(value);
  }

  useEffect(() => {
    const sub = deviceService.getAvailableDevices().pipe(
      map((devices: Device[]) => devices.map(device => ({value: device.attribute, text: device.name})))
    ).subscribe(seElements)

    return () => sub.unsubscribe();
  }, []);


  return (
    <div className={styles.container}>
      <Select 
        elements={elements || []}
        required={required}
        placeholder={(elements && !elements.length) ? t('placeholder.noDevicesAvailable') : t('placeholder.selectDevice')}
        value={value}
        onChange={handleChange}
        disabled={!elements || (elements && !elements.length) || disabled}
        error={error}
        helperText={helperText}
      />

      {
        !elements && <CircularProgress />
      } 
    </div>
    
  );
});

export default SelectDeviceComponent;
