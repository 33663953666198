import React, {memo, FunctionComponent, useState, useEffect} from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  XAxis, YAxis, CartesianGrid, Tooltip,
  ResponsiveContainer, ReferenceArea, LineChart, Line,
} from 'recharts';
import moment from 'moment';
import { MetricsView } from '../../../../lib/interfaces/metric-history.interface';
import { MeasurementKeys } from '../../../../lib/interfaces/measurement-keys.type';
import {Button, ChartTooltip, Typography} from '../../../../lib/components';
import { metricService } from '../../../../services';
import { AggregateBy } from '../../../../lib/constants/aggregation.enum';
import { ValueExtension } from '../../../../lib/constants/value-extension.constant'
import useAggreationState from '../../../../hooks/useAggreationState';
// import { AggregationState } from '../../../../lib/interfaces/aggregation-state.interface';
import {isSimplifiedHistory} from "../../../../lib/utils/is-simplified-history";
import {colors} from "../../../../theme/colors";
import {getMetricColor, Metric} from "../../../../lib/utils/metric-colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: 25,
    },
    container: {
      width: '99%',
      height: '65%'
    },
    oneGraphContainer: {
      height: '100%',
    },
    chartHead: {
      paddingLeft: 30,
      paddingRight: 10,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between'
    },
    button: {
      marginRight: 16,
      width: 104,
      padding: 15
    },
    input: {
      padding: 10
    },
    tootlip: {
      borderRadius: ''
    },
    referenceArea: {
      backgroundColor: theme.palette.primary.main
    },
    graphLegend: {
      display: 'flex',
      alignItems: 'center'
    },
    graphLegendItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,
      "&:last-child": {
        marginRight: 30,
      }
    },
    text: {
      margin: 0
    },
    line: {
      width: 36,
      height: 2,
      marginBottom: 7
    }
  })
);

interface Props {
  metrics: MetricsView[],
  hrMetrics: MetricsView[],
  keyName: MeasurementKeys,
  date?: string,
  hrField: boolean,
  setGraphClick: (value: boolean) => void;
  setButtonDisable: (value: boolean) => void;
  setTableView: (value: boolean) => void;
  tableView: boolean;
  lastHour: boolean;
}

interface measurementObj {
  [key: string]: any
}

const DATE_FORMAT = 'MMM DD HH:mm';

const renderTooltipContent = (current: AggregateBy|string, keyName: any) => ({ active, payload, label }: any) => {
  if (active) {
    const date = label;
    const data = payload[0] && payload[0].payload;
    const min = data && data.min;
    const max = data && data.max;
    const avg = data && data.avg;
    const message = data && data.message;
    return <ChartTooltip
      min={min}
      max={max}
      avg={avg}
      date={date}
      current={current}
      onlyDate={isSimplifiedHistory(keyName)}
      keyName={keyName}
      message={message}
    />
  }
};

/*const notFilteredByHour = (aggregationState: AggregationState) => {
  return aggregationState.aggregateBy !== AggregateBy.MINUTES;
}*/

const customAxisTickRenderer = (bottomText: String, topText: String) => (label:any) => {
  const { x, y } = label;
  let text;
  if (label.index === 0) {
    text = bottomText;
  } else if (label.index === label.visibleTicksCount - 1) {
    text = topText;
  } else {
    text = '';
  }

  return (
    <text x={x-70} y={y} fill={colors.exhibitionGraphTextColorGray}>{text}</text>
  );
};

const DisplayHistoryChart: FunctionComponent<Props> = memo(({
  metrics, keyName, hrMetrics, hrField, setGraphClick, setButtonDisable, tableView, setTableView, lastHour
}) => {
  const styles = useStyles();
  const { t } = useTranslation(); 

  const aggregationState = useAggreationState();

  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');

  const customYAxisLabels = new Map([
    ['p', customAxisTickRenderer('Horizontal', 'Upright')],
    ['af2', customAxisTickRenderer('Low', 'High')]
  ]);

  useEffect(() => {
    if (lastHour) {
      lastHourData();
      const interval = setInterval(() => {
        lastHourData()
      }, 60000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastHour])


  const zoom = (event: any) => {
    if (event) {
      // todo: fix aggregation level if date range is selected
      setGraphClick(true);
      setButtonDisable(false);
      const current = aggregationState.aggregateBy;

      let aggregatedBy = '';
      if (!current || current === AggregateBy.DAYS) {
        aggregatedBy = AggregateBy.HOURS;
      } else if (current === AggregateBy.HOURS) {
        aggregatedBy = AggregateBy.MINUTES;
      } else {
        aggregatedBy = AggregateBy.MINUTES;
      }

      const left = moment(refAreaLeft, DATE_FORMAT);
      const rightMoment = moment(refAreaRight || refAreaLeft, DATE_FORMAT);
      const right = current !== AggregateBy.MINUTES ? rightMoment
        .add(1, String(current).toLowerCase() as 'days'|'hours'|'minutes') :
        rightMoment;

      const leftToRight = [left, right];

      const diff = leftToRight[1].diff(leftToRight[0], 'minutes');
      if (diff <= 0) {
        leftToRight.reverse();
      }

      metricService.setAggregation(
        aggregatedBy,
        leftToRight[0].format(),
        leftToRight[1].format()
      )
    }
    
    setRefAreaLeft('');
    setRefAreaRight('');
  }

  const lastHourData = () => {
    if (lastHour) {
      const currentTime = moment().format();
      const previousHour = moment().subtract(1, 'hours').format();

      metricService.setAggregation(
        AggregateBy.MINUTES,
        previousHour,
        currentTime
      )

      setRefAreaLeft('');
      setRefAreaRight('');
    }
  }

  const measurementIndicators: measurementObj = {
    "hr": {
      min: 30,
      max: 240,
    },
    "rr": {
      min: 2,
      max: 60,
    },
    "bt": {
      min: 35,
      max: 42,
    },
    "spo2": {
      min: 70,
      max: 100,
    },
    "ce": {
      min: 0,
      max: 3,
    },
  };

  // const checkCurrentField = (currentField: string) => currentField === "ce" || currentField === "RR";

  const renderGraphLegend = (dataKey: string) => {
    /*const graphLegendText: any = [
      {
        text: 'min',
        color: (dataKey === 'hr' || dataKey === 'spo2') ? '#9BF39D' : '#1FA1E9'
      },
      {
        text: 'avg',
        color: dataKey === 'hr' ? "#F17176" : dataKey === 'spo2' ? "#1FA1E9" : "#9EA2A5"
      },
      {
        text: 'max',
        color: (dataKey === 'hr' || dataKey === 'spo2') ? '#000000' : '#F17176'
      }
    ];*/

    return (
      <div className={styles.graphLegend}>
        {(!isSimplifiedHistory(keyName) && dataKey === 'hr') && <Button
          rootClass={styles.button}
          onClick={() => setTableView(!tableView)}
        >
          {t('button.toggleHistoryTable')}
        </Button>}
      </div>
    )
  };

  const renderGraph = (dataKey: string) => {
    // const greenColorBlocks = ['spo2', 'hr'];
    const keyForDisableTooltip = ['p', 'af2'];
    const lineColor = getMetricColor(dataKey as Metric);
    return (
      <ResponsiveContainer width={'100%'} height={'80%'}>
        <LineChart
          data={dataKey !== 'hr' ? metrics : hrMetrics}
          margin={{top: 10, right: 30, bottom: 10, left: 30}}
          onMouseDown = { lastHour ? () => {} : (e) => e && setRefAreaLeft(e.activeLabel) }
          onMouseMove = { lastHour ? () => {} : (e) => e && refAreaLeft && setRefAreaRight(e.activeLabel) }
          onMouseUp = { lastHour ? () => {} : zoom }
        >

          <CartesianGrid strokeDasharray="7 7" horizontal={false}/>

          <XAxis
            angle={-22.5}
            dataKey={(dataObject) => moment(dataObject.aggregatedTime).format(DATE_FORMAT)}
            dy={10}
            stroke={colors.exhibitionGraphTextColorGray}
            tick={{fontSize: 12}}
          />
          <YAxis
            type="number"
            // dataKey='min'
            yAxisId="1"
            domain={[ dataMin => (measurementIndicators[dataKey] && (dataMin > measurementIndicators[dataKey].min)) ? measurementIndicators[dataKey].min : dataMin,
              dataMax => (measurementIndicators[dataKey] && (dataMax < measurementIndicators[dataKey].max) ? measurementIndicators[dataKey].max : dataMax) ]}
            tick={isSimplifiedHistory(dataKey) ? customYAxisLabels.get(dataKey) : undefined}
            stroke={colors.exhibitionGraphTextColorGray}
          />

          { !keyForDisableTooltip.includes(dataKey) ? <Tooltip content={renderTooltipContent(aggregationState.aggregateBy, dataKey)}/> : null}

          <Line
            connectNulls
            type="monotone"
            strokeWidth="2"
            yAxisId="1"
            dataKey="avg"
            stroke={lineColor}
            fill={lineColor} />
          {
            (refAreaLeft && refAreaRight) ? (
                <ReferenceArea fill="#8884d8" yAxisId="1" x1={refAreaLeft} x2={refAreaRight}  strokeOpacity={1} /> )
              : null
          }
        </LineChart>
      </ResponsiveContainer>
    )
  };

  const renderHRGraphBlock = () => {
    const hrKey = 'hr';
    return (
      <div className={styles.container}>
        <div className={styles.chartHead} style={{color: getMetricColor(hrKey)}}>
          <Typography>{t(`healthData.${hrKey}`)}{ValueExtension[hrKey] && `, ${ValueExtension[hrKey]}`}</Typography>
          {
            (!isSimplifiedHistory(keyName)) && renderGraphLegend(hrKey)
          }
        </div>
        {renderGraph(hrKey)}
      </div>
    )
  };

  return (
    <div className={styles.mainContainer}>
      { !hrField && renderHRGraphBlock() }
      <div className={hrField ? styles.oneGraphContainer : styles.container}>
        {/*change graph headers color*/}
        <div className={styles.chartHead} style={{color: getMetricColor(keyName as Metric)}}>
          <Typography>{t(`healthData.${keyName}`)}{ValueExtension[keyName] && `, ${ValueExtension[keyName]}`}</Typography>
          { !isSimplifiedHistory(keyName) && renderGraphLegend(keyName) }
        </div>
        {renderGraph(keyName)}
      </div>
    </div>
  );
});

export default DisplayHistoryChart;
