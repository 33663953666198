import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import { Case } from '../../../../lib/interfaces/case.interface';
import { BatteryIcons, Typography } from '../../../../lib/components';
import { patientMeasurement } from '../../../../lib/utils/patient-measurement';
import { colors } from '../../../../theme/colors';
import UrgentModeComponent from '../UrgentModeComponent';
import moment from "moment";
import HistoryFilter from "../../../History/components/HistoryFilter";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { dateTimeFormat } from "../../../../lib/utils/format-date";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: 64,
      paddingTop: 13,
      paddingBottom: 15,
      display: 'flex',
      backgroundColor: colors.white,
      width: '100%',
      justifyContent: 'space-between'
    },
    item: {
      display: 'flex',
      // width: '50%',
      flexDirection: 'row',
    },
    itemContent: {
      paddingLeft: 3
    },
    batteryItem: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 15,
      paddingRight: 10,
      borderRight: '1px solid #E0E0E0',
      marginRight: 10,
      // width: 100,
    },
    iconContainer: {
      marginTop: 5,
      cursor: 'pointer',
    },
    tooltipText: {
      fontWeight: 400,
      fontSize: 12,
      margin: 0,
      paddingBottom: 1
    },
    tooltip: {
      width: 280,
    }
  })
);

//todo: fix duplication with DetailsData
function isOldMeasurement(lastMeasurementDate: string | null) {
  if (lastMeasurementDate === null) {
    return true;
  } else {
    return moment().diff(moment(lastMeasurementDate), 'minutes') > 60;
  }
}

interface Props {
  patientCase: Case;
  setFilter: (value: string) => void;
  setChosenElement: (value: number) => void;
  setGraphClick: (value: boolean) => void;
  setButtonDisable: (value: boolean) => void;
  setLastHour: (value: boolean) => void;
  filter: any;
  graphClick: boolean;
  buttonDisable: boolean;
}

const DetailsFooter: FunctionComponent<Props> = memo(({ patientCase, setFilter, setChosenElement, filter, graphClick, setGraphClick, setButtonDisable, buttonDisable, setLastHour }) => {
  const styles = useStyles();
  // const { t } = useTranslation();

  const batteryStatus = patientMeasurement('bs', patientCase.lastMeasurement?.params.bs ?? null);
  const lastMeasurementDate = patientCase.lastMeasurementDate;
  const dataIsAbsent = batteryStatus.value === null || batteryStatus.value === undefined || isOldMeasurement(lastMeasurementDate);
  const batteryViewText = dataIsAbsent ? '-' : patientCase?.lastMeasurement?.params?.bs.value && patientCase?.lastMeasurement?.params?.bs.value < 20 ? 'LOW' : `${batteryStatus.value} ${batteryStatus.extension}`;
  return (
    <div className={styles.container}>

      <div className={styles.item}>
        <div className={styles.batteryItem}>
          {dataIsAbsent ? null : patientCase?.lastMeasurement?.params?.bs?.message &&
          patientCase?.lastMeasurement?.params?.bs?.message?.length > 0 ?
            <>
              <div
                className={styles.iconContainer}
                data-tip
                data-for={'footerId'}
              >
                <InfoOutlinedIcon/>
              </div>
              <ReactTooltip id={'footerId'} place='top' backgroundColor='#FBFCFD' effect='solid' type="light" className={styles.tooltip}>
                <p className={styles.tooltipText}>{`${patientCase?.lastMeasurement?.params?.bs?.message?.[0]
                ?? ''}${patientCase?.lastMeasurement?.params?.bs?.previousDataDate ? 
                  ` ${dateTimeFormat(patientCase?.lastMeasurement?.params?.bs?.previousDataDate)}` : ''}${patientCase?.lastMeasurement?.params?.bs?.message?.[1] 
                ?? ''}`}</p>
              </ReactTooltip>
            </>
            : <BatteryIcons batteryValue={Number(batteryStatus.value)} />}
          <div className={styles.itemContent}>
            {/*<Typography>{t('footer.batteryLevel')}</Typography>*/}
            <Typography>{batteryViewText}</Typography>
          </div>
        </div>

        <UrgentModeComponent
          caseId={patientCase.caseId}
          deviceId={patientCase.deviceId}
          urgentAttentionMode={patientCase.urgentAttentionMode}
          normalModeMinutes={patientCase.normalModeMinutes}
          pendingStatus={patientCase.reportingModeStatus}
          errorField={patientCase.errorMessage}
        />
      </div>
      <div className={styles.item}>
        <HistoryFilter
          setFilter={setFilter}
          setChosenElement={setChosenElement}
          filter={filter}
          graphClick={graphClick}
          setGraphClick={setGraphClick}
          buttonDisable={buttonDisable}
          setButtonDisable={setButtonDisable}
          setLastHour={setLastHour}
        />
      </div>
    </div>
  );
});

export default DetailsFooter;
