import React, {memo, FunctionComponent} from 'react';
import MUIPaper from '@material-ui/core/Paper'

interface Props {
  elevation?: number;
  square?: boolean;
  variant?: 'elevation'|'outlined'
  className?: any;
  style?: any;
}

const Paper: FunctionComponent<Props> = memo(({ children, elevation, square, variant, className, style }) => {
  return (
    <MUIPaper
      elevation={elevation}
      square={square}
      variant={variant}
      classes={{
        root: className
      }}
      style={style}
    >
      {children}
    </MUIPaper>
  );
});

export default Paper;
