import React, {memo, FunctionComponent} from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ButtonBase from '../ButtonBase';
import Menu from '../Menu';
import { authService, metricService } from '../../../services';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: '-18px 15px 0 15px'
    },
  })
);


interface Props {}

const elements = [
  {
    titleKey: 'contactUs', handler: () => {
      window.open(
        'https://support.tektelic.com/portal/en/signin',
        '_blank'
      );
    },
  },
  {
    titleKey: 'logOut', handler: () => {
    authService.signOutProcess().subscribe();
    metricService.setAggregationBtn(0);
    },
  }
];

const UserMenu: FunctionComponent<Props> = memo(() => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonBase className={styles.container} onClick={handleClick}>
        <AccountCircleIcon />
      </ButtonBase>
      <Menu
        elements={elements}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
    </>
  );
});

export default UserMenu;
