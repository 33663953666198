import { MeasurementKeys } from '../interfaces/measurement-keys.type';
import { ValueLimit } from '../constants/value-limit.constant';
import { ValueExtension } from '../constants/value-extension.constant';
import { LastMeasurement } from '../interfaces/last-measurement.interface';
import {
  AdditionalMeasurementData,
  MeasurementsItemView,
  MeasuremetView
} from '../interfaces/measurement-view.interface';
import { valueHandler, valueIconHandler } from './display-value-content';

export function patientMeasurement(
  key: MeasurementKeys, item: MeasurementsItemView | null, additionalData?: AdditionalMeasurementData
): MeasuremetView {
  return {
    key,
    value: valueHandler(key, item?.value ? item.value : null, additionalData),
    iconName: valueIconHandler(key, item?.value ? item.value : null),
    limit: ValueLimit[key] || {min: null, max: null},
    extension: ValueExtension[key],
    message: item?.message ? item.message : null,
    previousDataDate: item?.previousDataDate ? item.previousDataDate : null
  };
}

export function measurementList(
  keys: MeasurementKeys[],
  measurement: LastMeasurement | null,
  additionalData: AdditionalMeasurementData
): MeasuremetView[] {
  return keys.map(key => patientMeasurement(key, measurement ? measurement[key] : null, additionalData));
}
