import { Limit } from './../interfaces/limit.interface';

export const checkLimit = (limit: Limit, value: number|boolean|string): boolean => {
  const {max, min} = limit;
  const maxBorderExceeded = max && value >= max;
  const minBorderExceeded = min && value <= min;
  return Boolean(minBorderExceeded || maxBorderExceeded);
}

type Value = number|boolean|string;

export const checkLimitExtended = (limit: Limit, minValue: Value, maxValue: Value): boolean => {
  if (!limit) {
    return false;
  }
  const {max, min} = limit;
  const maxBorderExceeded = max && maxValue >= max;
  const minBorderExceeded = min && minValue <= min;
  return Boolean(minBorderExceeded || maxBorderExceeded);
}

