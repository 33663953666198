import { CognitoUserExtended } from './../../lib/models/cognito-user';
import { CHALLANGE_NAME } from './../../lib/constants/challange-names.enum';
import { User } from './../../lib/interfaces/user.interface';
import { VERIFICATION_STATE } from './../../lib/constants/verification-state.enum';
import { AuthApi } from './../../lib/api/auth.api';
import { BehaviorSubject, from, Observable, throwError, of } from 'rxjs';
import { take, catchError, tap, map, switchMap } from 'rxjs/operators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import errorService from '../errorService';

const AuthService = () => {
  const store$ = new BehaviorSubject<string>(VERIFICATION_STATE.PENDING);
  const storedUser$ = new BehaviorSubject<CognitoUser|null>(null);

  const updateStatus = (status: string) => {
    store$.next(status);
  };

  const getStatus = (): Observable<any> => {
    return getSession().pipe(
      catchError(e => of(false)),
      tap(value => {
        updateStatus(value ? VERIFICATION_STATE.AUTHORIZED : VERIFICATION_STATE.NOT_AUTHORIZED)
      }),
      switchMap(() => store$.asObservable())
    )
  }

  // * Get current local session 
  const getSession = (): Observable<any> => {
    return from(AuthApi.currentSession()).pipe(
      take(1),
      catchError(error => {
        return throwError(error);
      }),
    );
  };

  // * Fetch user info from user pool 
  const getUserInfo = (): Observable<User> => {
    return from(AuthApi.currentUserInfo()).pipe(
      take(1),
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  // * Sign in process
  const signIn = (email: string, password: string): Observable<CognitoUserExtended | any> => {
    return from(AuthApi.signIn(email, password)).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  };

  const signInProcess = (email: string, password: string): Observable<CognitoUserExtended | null> => {
    return signIn(email, password).pipe(
      map((user: CognitoUserExtended) => {
        if (user.challengeName === CHALLANGE_NAME.NEW_PASSWORD_REQUIRED) {
          return user;
        }
        updateStatus(VERIFICATION_STATE.AUTHORIZED);
        return null;
      })
    )
  }

  const signInConfirm = (password: string, requiredAttributes: any): Observable<CognitoUser | any> => {
    return storedUser$.asObservable().pipe(
      take(1),
      switchMap((user: CognitoUser|null) => {
        return user ?
          from(AuthApi.completeNewPassword(user, password, requiredAttributes)) 
        :
          throwError({})
      }),
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
      tap(() => {
        storedUser$.next(null);
        updateStatus(VERIFICATION_STATE.AUTHORIZED)
      })
    );
  }

  // * Forgot password process
  const forgotPassword = (email: string): Observable<void> => {
    return from(AuthApi.forgotPassword(email)).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  }

  const resetPassword = (email: string, code: string, password: string): Observable<void> => {
    return from(AuthApi.forgotPasswordSubmit(email, code, password)).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  }

  // * Sign out process
  const signOut = (): Observable<any> => {
    return from(AuthApi.signOut()).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  }

  const signOutProcess = (): Observable<any> => {
    return signOut().pipe(
      tap(() => updateStatus(VERIFICATION_STATE.NOT_AUTHORIZED))
    );
  }

  const storeUser = (user: CognitoUser) => storedUser$.next(user);

  return {
    store: store$.asObservable(),
    getStatus,
    getSession,
    getUserInfo,
    signInProcess,
    signOutProcess,
    signInConfirm,
    forgotPassword,
    resetPassword,
    storeUser
  };
};

const singleton = AuthService();
export default Object.freeze(singleton);
