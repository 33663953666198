import React, {memo, FunctionComponent} from 'react';
import { useTranslation } from 'react-i18next';
import { Limit } from '../../../../lib/interfaces/limit.interface';
import Paper from '../../../../lib/components/Paper';
import { ButtonBase, Icon, Typography } from '../../../../lib/components';
import {useStyles} from '../DataItem'
import {getMetricColor, Metric} from "../../../../lib/utils/metric-colors";


interface Props {
  data: number|boolean|string;
  text: string;
  limit: Limit;
  extension: string;
  measure: string;
  action?: () => void
  iconName?: string;
  noData?: boolean;
  chosen?: string;
  restricted?: boolean;
  icon?: string;
  disabled?: boolean;
  message?: string[] | null;
  previousDataDate?: string | null;
}

const chosenCurrent = (current: string, chosen: string|undefined): boolean => {
  return current === chosen;
}

const HealthDataItem: FunctionComponent<Props> = memo(({
  data, text, limit, extension, action, iconName, measure, chosen, restricted, icon, disabled, message, previousDataDate
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const isChosen = chosenCurrent(measure, chosen);
  let textColor;
  if (icon) {
    textColor = '#FBFCFD';
  } else {
    textColor = getMetricColor(measure as Metric);
  }
  if (measure === 'bt' &&
    //data is valid number
    data !== null &&
    data !== undefined &&
    data !== '' &&
    !isNaN(Number(data))
  ) {
    // round data to 1 digit after the point
    data = Math.round(Number(data) * 10) / 10;
  }
  const isTileSmall = measure === 'p' || measure === 'af2';
  const isDataPresent = data !== '-';
  return (
    <ButtonBase disableRipple={restricted} className={styles.container} onClick={action} disabled={disabled}>
      <Paper
        className={`${styles.item} ${isChosen && styles.chosen} ${isTileSmall && styles.bigPadding}`}
        style={isChosen ? {borderColor: textColor} : null}
        elevation={isChosen ? 5 : 1}
      >
        <div style={{color: textColor, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center'}}>
          <Typography className={styles.title}>
            {text}
            {extension ? `, ${extension}` : ''}
          </Typography>

          <div>
            {
              icon ? <div className={styles.iconBlock}><img className={styles.iconImg} src={icon} alt="device_icon"/></div> : null
            }
            {!icon && <div className={styles.data}>
              {
                iconName ? <Icon fontSize='small'>{iconName}</Icon> : null
              }
              <Typography className={`${styles.dataItem} ${isTileSmall && isDataPresent && styles.dataItemSmall}`}>{t(String(data))}</Typography>
            </div>
            }
          </div>
        </div>

      </Paper>
    </ButtonBase>
  );
});

export default HealthDataItem;
