import { useState, useEffect } from 'react';
import { LoaderType } from '../../lib/interfaces/loader.interface';
import loaderService from '../../services/loaderService';

const useLoader = () => {
  const [isLoader, setLoading]: [LoaderType, (arg: any) => void] = useState<LoaderType>(null);

  useEffect(() => {
    const sub = loaderService.state
      .subscribe(setLoading)
    return () => sub.unsubscribe();
    /* eslint-disable */
  }, []);

  return isLoader;
};

export default useLoader;
