import { Case } from './../../lib/interfaces/case.interface';
import { useState, useEffect } from 'react';
import { caseService } from '../../services';
import { switchMapTo, map } from 'rxjs/operators';
import { sortByDate } from '../../lib/utils/stable-sort';
import { viewPatientCase } from '../../lib/utils/view-patient-case';
import { CaseViewModel, caseViewModel } from '../../lib/models/case-view.model';

const useCaseList = () => {
  const [cases, setCases] = useState<CaseViewModel[]|null>(null);

  useEffect(() => {
    const sub = caseService.getCasesList().pipe(
      switchMapTo(caseService.cases),
      map(sortByDate<Case>('initiationDate')),
      map(viewPatientCase(caseViewModel))
    ).subscribe(setCases)

    return () => sub.unsubscribe();
  }, []);

  return cases;
};

export default useCaseList;
