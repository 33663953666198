import React, {memo, FunctionComponent} from 'react';
import Snackbar from '../Snackbar';
import { MessageVariant } from '../../constants/message-variant.enum';

interface Props {
  error: any;
  setError: (value: any) => void;
}

const ErrorDisplay: FunctionComponent<Props> = memo(({ error, setError }) => {
  const closeMessage = () => setError(null);

  return (
    <Snackbar
      open={Boolean(error)}
      type={MessageVariant.ERROR}
      message={error ? error.message : ''}
      onClose={ closeMessage }
    />
  );
});

export default ErrorDisplay;
