import React, {memo, FunctionComponent, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Case } from '../../../../lib/interfaces/case.interface';
import { of } from 'rxjs';
import { delay, mergeMap, repeat, take } from 'rxjs/operators';
import { caseService } from '../../../../services';
import { toMinutes } from '../../../../lib/utils/to-minutes';
import { MeasurementKeys } from '../../../../lib/interfaces/measurement-keys.type';
import { measurementList } from '../../../../lib/utils/patient-measurement';
import HealthDataItem from '../HealthDataItem';
import moment from "moment";
import eDoctorIcon from '../../../../theme/e-doctor.png';
import eBeatIcon from '../../../../theme/e-beat.png';
import {Fab} from "../../../../lib/components";
import {Fullscreen, FullscreenExit} from "@material-ui/icons";
// import DataItem from "../DataItem";
// import {dateTimeFormat} from "../../../../lib/utils/format-date";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 5,
      marginBottom: 16
    }
  })
);

interface Props {
  patientCase: Case;
  setOutterChosen: (value: MeasurementKeys) => void;
  chosen: string,
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
}

function isOldMeasurement(lastMeasurementDate: string | null) {
  if (lastMeasurementDate === null || lastMeasurementDate === undefined) {
    return true;
  } else {
    return moment().diff(moment(lastMeasurementDate), 'minutes') > 60;
  }
}

const DetailsData: FunctionComponent<Props> = memo(({ patientCase, setOutterChosen, chosen, isFullScreen, setIsFullScreen }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const measuresForDisplay: Array<MeasurementKeys> = patientCase.deviceType === 'eBeat'
    ? ['bt', 'hr', 'rr', 'spo2']
    : ['bt', 'hr', 'rr', 'ce', 'p', 'af2'];
  const iconName = patientCase.deviceType === 'eBeat' ? eBeatIcon : eDoctorIcon;

  const chooseItem = (key: MeasurementKeys) => () => {
    caseService.getCasesList().subscribe();
    setOutterChosen(key);
  }

  useEffect(() => {
    const sub = of({}).pipe(
      mergeMap(() => caseService.getCasesList().pipe(
        take(1)
      )),
      delay(toMinutes(1)),
      repeat(),
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  return (
    <div className={styles.container}>
      <HealthDataItem
        text={t(`placeholder.deviceType`)}
        data={''}
        limit={{max: null, min: null}}
        extension={''}
        measure={''}
        icon={iconName}
        disabled={true}
      />
      {
          measurementList(measuresForDisplay, patientCase.lastMeasurement?.params ?? null, {chestSize: patientCase.chestSize}).map(item => {
            // * Direct check if value is null, probably will be changed on BE
            const dataIsAbsent = item.value === null || item.value === undefined || isOldMeasurement(patientCase.lastMeasurementDate);
            const data = dataIsAbsent ? '-' : item.value;
            const extension = dataIsAbsent ? '' : item.extension;
            const iconName = dataIsAbsent ? undefined : item.iconName;
            const message = dataIsAbsent ? null : item.message;
            const previousDataDate = dataIsAbsent ? null : item.previousDataDate;
            return (
              <HealthDataItem
                key={item.key}
                measure={item.key}
                chosen={chosen}
                data={data}
                extension={extension}
                limit={item.limit}
                iconName={iconName}
                text={t(`healthData.${item.key}`)}
                action={chooseItem(item.key)}
                restricted={false}
                message={message}
                previousDataDate={previousDataDate}
              />
            )
          })
      }
      <div style={{flexGrow: 2}}></div>
      <div style={{marginTop: 20, marginRight: 20, position: 'absolute', right: 0}}>
        <Fab size={'small'} onClick={()=> setIsFullScreen(!isFullScreen)}>
          {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
        </Fab>
      </div>

      {/*<DataItem text={t('footer.sensorUpdate')} data={patientCase.lastMeasurementDate ? dateTimeFormat(patientCase.lastMeasurementDate) : '-'}/>*/}
    </div>
  );
});

export default DetailsData;
