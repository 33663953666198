export enum METRICS_NAME {
  BATTERY_STATUS = 'battery-status',
  BODY_TEMPERATURE = 'body-temperature',
  RESPIRATION_RATE = 'respiration-rate',
  CHEST_EXPANSION = 'chest-expansion',
  COUGH_SNEEZE_COUNT = 'cough-sneeze-count',
  ACTIVITY_FLAG = 'activity-flag',
  ACTIVITY_INTENSITY = 'activity-intensity',
  POSITION = 'position',
  REST_MODE = 'rest-mode',
  URGENT_ATTENTION_MODE = 'urgent-attention-mode',
  HEART_RATE = 'heart-rate',
  HEART_RATE_VARIABILITY = 'heart-rate-variability',
  OXYGEN_SATURATION_LEVEL = 'oxygen-saturation-level',
}

export const MetricNameMap = {
  af: METRICS_NAME.ACTIVITY_FLAG,
  af2: METRICS_NAME.ACTIVITY_INTENSITY,
  bs: METRICS_NAME.BATTERY_STATUS,
  bt: METRICS_NAME.BODY_TEMPERATURE,
  ce: METRICS_NAME.CHEST_EXPANSION,
  csc: METRICS_NAME.COUGH_SNEEZE_COUNT,
  hr: METRICS_NAME.HEART_RATE,
  p: METRICS_NAME.POSITION,
  rm: METRICS_NAME.REST_MODE,
  rr: METRICS_NAME.RESPIRATION_RATE,
  uam: METRICS_NAME.URGENT_ATTENTION_MODE,
  hrv: METRICS_NAME.HEART_RATE_VARIABILITY,
  spo2: METRICS_NAME.OXYGEN_SATURATION_LEVEL,
}

export const MetricNameSignChars = {
  af: 0,
  af2: 0,
  bs: 0,
  bt: 2,
  ce: 1,
  csc: 0,
  hr: 0,
  p: 0,
  rm: 0,
  rr: 0,
  uam: 0,
  hrv: 0,
  spo2: 0,
}
