import React, {memo, FunctionComponent, useState, useMemo, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Paper } from '../../../../lib/components';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../lib/constants/date-format.constant';
import { colors } from '../../../../theme/colors';
import { metricService } from '../../../../services';
import { AggregateBy } from '../../../../lib/constants/aggregation.enum';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: 'none',
      /*borderRadius: 8,
      padding: 30*/
    },
    buttons: {
      paddingRight: 10,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
    },
    button: {
      marginRight: 16,
      width: 104,
      padding: 15
    },
    export: {
      borderLeft: `3px solid ${colors.backgroundGray}`,
      paddingLeft: 45,
    },
    exportButton: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8
    },
    text: {
      paddingLeft: 16
    }
  })
);

interface Props {
  setFilter: (value: string) => void;
  setChosenElement: (value: number) => void;
  setGraphClick: (value: boolean) => void;
  filter: any;
  graphClick: boolean;
  setButtonDisable: (value: boolean) => void;
  setLastHour: (value: boolean) => void;
  buttonDisable: boolean;
}

const HistoryFilter: FunctionComponent<Props> = memo(({ setFilter, setChosenElement, filter, graphClick, setGraphClick, buttonDisable, setButtonDisable, setLastHour }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [chosen, setChosen] = useState(0);
  const data = useMemo(() => ([
    {label: t('button.lastHour'), date: moment.utc().subtract(1, 'day').format(DATE_FORMAT), aggregate: AggregateBy.HOURS},
    {label: t('button.last24Hours'), date: moment.utc().subtract(1, 'day').format(DATE_FORMAT), aggregate: AggregateBy.HOURS},
    {label: t('button.lastWeek'), date: moment.utc().subtract(1, 'week').format(DATE_FORMAT), aggregate: AggregateBy.DAYS},
    {label: t('button.twoWeeks'), date: moment.utc().subtract(2, 'week').format(DATE_FORMAT), aggregate: AggregateBy.DAYS},
    // eslint-disable-next-line
  ]), []);

  useEffect(() => {
    if (chosen === 3 && !graphClick) helperButtonDisable();
  });

  useEffect(() => {
    metricService.aggregationBtnState.subscribe(setChosen);
  }, []);

  const helperButtonDisable = () => setButtonDisable(true);

  const chooseElement = 
    (data: {label: string, date: string, aggregate: AggregateBy}[]) =>
    (element: number) => 
    () => {
      chosen !== element && metricService.resetAggregation(
        data[element].aggregate
      );
      setFilter(data[element].date);
      setChosenElement(element);
      setChosen(element);
      metricService.setAggregationBtn(element);
      setButtonDisable(false);
      setLastHour(false);
      if (element === 3) setButtonDisable(true);
      if (element === 0) {
        setLastHour(true);
        // setButtonDisable(true);
      }
  }
  const zoomOut = (date: string) => () => {
    if (graphClick) {
      const diff = moment().diff(date, 'days');
      metricService.resetAggregation(diff > 1 ? AggregateBy.DAYS : AggregateBy.HOURS);
      setGraphClick(false);
    } else {
      changeDataTime();
    }
  }

  const changeDataTime = () => {
    if (chosen === 0) {
      return changeDataHelper(1);
    } else if (chosen === 1) {
      return changeDataHelper(2);
    } else if (chosen === 2) {
      return changeDataHelper(3);
    } else {
      return null;
    }
  }

  const changeDataHelper = (element: number) => {
    setFilter(data[element].date);
    setChosenElement(element);
    setChosen(element);
    setLastHour(false);
    metricService.resetAggregation(
      data[element].aggregate
    );
  }

  return (
    <Paper className={styles.container}>
      <div className={styles.buttons}>
        {
          data.map((item, i) => {
            return (
              <Button
                key={item.date}
                rootClass={styles.button}
                color={chosen === i ? 'primary' : 'secondary'}
                onClick={chooseElement(data)(i)}
              >
                {item.label}
              </Button>
            )
          })
        }
        <Button
          rootClass={styles.button}
          onClick={zoomOut(filter)}
          disabled={buttonDisable}
        >
          {t('button.zoomOut')}
        </Button>
      </div>
    </Paper>
  );
});

export default HistoryFilter;
