import { switchMap } from 'rxjs/operators';
import { useState, useEffect } from 'react';
import { Device } from '../../lib/interfaces/device.interface';
import { deviceService } from '../../services';

const useDevices = () => {
  const [devices, setDevices] = useState<Device[]|null>(null);

  useEffect(() => {
    const sub = deviceService.getAllDevices().pipe(
      switchMap(() => deviceService.deviceState)
    )
    .subscribe(setDevices)
    return () => sub.unsubscribe();
  }, []);

  return devices;
};

export default useDevices;
