import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import MUITableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { HeadCell } from '../../interfaces/head-cell.interface';
import Icon from '../Icon';
import { colors } from '../../../theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    rowButton: {
      width: '100%'
    },
    tableCellRoot: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 5,
      paddingLeft: 5,
      height: 30,
    },
    emptyCell: {
      borderBottom: 'none'
    },
    icon: {
      paddingRight: 5
    },
    selectedRow: {
      backgroundColor: `${colors.backgroundBlue}!important`,
      fontWeight: 'bold'
    },
    hoverRow: {
      '&:hover': {
        backgroundColor: `${colors.backgroundBlue}!important`,
      }
    }
  })
);

interface Props {
  data: any[];
  emptyRows: number;
  headCells: HeadCell[];
  chosen?: string;
  onSelect?: (name: string) => void; 
}

const TableBody: FunctionComponent<Props> = memo(({ data, onSelect, headCells, emptyRows, chosen }) => {
  const styles = useStyles();
  const [selected, setSelected] = React.useState<string>(chosen || '');
  const isSelected = (name: string) => {
    return selected === name || chosen === name;
  };
  const selectRow = (selectedName: string) => {
    setSelected(selectedName);
    onSelect && onSelect(selectedName);
  };

  return (
    <MUITableBody>
      {
        data.map((row) => {
          const isItemSelected = isSelected(row.id);
          return (
            <TableRow
              hover
              role="checkbox"
              onClick={() => selectRow(row.id)}
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={`row-${row.id}`}
              selected={isItemSelected}
              classes={{
                selected: styles.selectedRow,
                hover: styles.hoverRow
              }}
            >
              {
                headCells.map(({ id }: HeadCell) => {
                  return (
                    <TableCell 
                      classes={{root: styles.tableCellRoot}} 
                      key={`cell-${id}`}
                    >
                      <div className={styles.tableCell}>
                        {
                          row[id].icon && 
                            <span className={styles.icon}>
                              <Icon fontSize="small">{row[id].icon}</Icon>
                            </span>
                        } 

                        {
                          row[id].renderItem ? 
                            row[id].renderItem 
                          :
                            row[id].value
                        }
                      </div>
                    </TableCell>
                  );
                })
              }
            </TableRow>
          );
        })
      }

      {emptyRows > 0 && (
        <TableRow>
          <TableCell classes={{root: styles.emptyCell}} colSpan={6} />
        </TableRow>
      )}
    </MUITableBody>
  );
});

export default TableBody;
