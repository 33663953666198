import { NewPatient } from './../../lib/interfaces/new-patient-form.interface';
import { PatientForm } from './../../lib/interfaces/patient-form.interface';
import { Api } from './../../lib/api/api';
import { Case, RawCase } from './../../lib/interfaces/case.interface';
import { API_ROUTES } from './../../lib/constants/api-routes.constant';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, catchError, switchMap, take, map } from 'rxjs/operators';
import errorService from '../errorService';
import deviceService from '../deviceService';

const CaseService = () => {
  const case$ = new BehaviorSubject<Case[]>([]);

  const getActiveCases = (): Observable<RawCase[]> => {
    return Api.get(API_ROUTES.CASE.GET_ALL_ACTIVE_CASES)
  }

  const getCasesList = (): Observable<Case[]> => {
    return getActiveCases().pipe(
      switchMap((caseList: RawCase[]) => {
        return deviceService.getAllDevices().pipe(
          map(devices => ({caseList, devices}))
        )
      }),
      map(({caseList, devices}) => {
        return caseList.map((item: RawCase) => {
          const deviceMatch = devices.find(deviceItem => deviceItem.attribute === item.deviceId)
          return {
            ...item,
            deviceName: deviceMatch ? deviceMatch.name : null
          } as Case
        });
      }),
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      tap(cases => {
        case$.next(cases);
      })
    );
  };

  const getAllPatientCases = (patientId: string): Observable<Case[]> => {
    return Api.get(API_ROUTES.CASE.GET_ALL_PATIENT_CASES(patientId)).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  }

  const getCaseById = (patientId: string, caseId: string): Observable<Case> => {
    return Api.get(API_ROUTES.CASE.GET_CASE_BY_ID(patientId, caseId)).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  }

  const getCurrentCase = (patientId: string): Observable<Case> => {
    return Api.get(API_ROUTES.CASE.GET_CURRENT_CASE(patientId)).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  }

  const createCase = (data: NewPatient): Observable<any> => {
    return Api.post(API_ROUTES.CASE.CREATE_CASE, data)
  }

  const createCaseFlow = (data: NewPatient): Observable<any> => {
    return createCase(data).pipe(
      switchMap(() => getCasesList()),
      take(1),
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    )
  }

  const resolveCase = (patientId: string): Observable<Case> => {
    return Api.post(API_ROUTES.CASE.RESOLVE_CASE(patientId), {}).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      switchMap(
        () => getCasesList(),
        (outerValue) => outerValue
      )
    )
  }

  const updateCase = (patientId: string, data: PatientForm): Observable<any> => {
    return Api.patch(API_ROUTES.CASE.UPDATE_CASE(patientId), data).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      }),
      switchMap(() => getCasesList())
    )
  }

  const getRecallMessage = (): Observable<any> => {
    return Api.get(API_ROUTES.CASE.NOTIFICATION).pipe(
      catchError(e => {
        const error = e.message || e.error || e;
        errorService.addError(error);
        return throwError(e);
      })
    );
  }

  return {
    cases: case$.asObservable(),
    getActiveCases,
    getCasesList,
    getCaseById,
    getCurrentCase,
    getAllPatientCases,
    resolveCase,
    updateCase,
    createCaseFlow,
    getRecallMessage,
  }
};

const singleton = CaseService();
export default Object.freeze(singleton);
