import React, {memo, FunctionComponent, useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import PatientCase from '../PatientCase';
import Details from '../Details';
import {useCaseList} from "../../hooks";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100vh',
      display: 'flex'
    }
  })
);

const Main: FunctionComponent = memo(() => {
  const styles = useStyles();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [patientId, setPatientId] = useState<string|null>(null);
  const patientCases = useCaseList();

  return (
    <div className={styles.container}>
      {!isFullScreen && <PatientCase setPatientId={setPatientId} patientCases={patientCases} />}
      <Details patientId={patientId} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen}/>
    </div>
  );
});

export default Main;
