import React, {memo, FunctionComponent, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, CircularProgress, Dialog } from '../../../../lib/components';
import DeleteIcon from '@material-ui/icons/Delete';
import { caseService } from '../../../../services';
import { take } from 'rxjs/operators';

interface Props {
  patientId: string;
}

const DeletePatientButton: FunctionComponent<Props> = memo(({ patientId }) => {
  const { t } = useTranslation();
  const toggleDialog = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submit = () => {
    toggleDialog();
    setLoading(true);
    caseService.resolveCase(patientId).pipe(take(1)).subscribe(
      () => setLoading(false),
      () => setLoading(false),
    )
  }

  return (
    <>
      {
        isLoading ? 
          <CircularProgress size={16} />
        :
          <ButtonBase onClick={toggleDialog}>
            <DeleteIcon />
          </ButtonBase>
      }
      
      <Dialog
        open={open}
        setOpen={setOpen}
        title={t('header.resolveCase')}
        contentText={t('text.resolveCase')}
        submit={submit}
        onCancel={toggleDialog}
      />
    </>
  );
});

export default DeletePatientButton;
