// Metrics in tiles
export type Metric =
  'bt' | // Skin temperature
  'hr' | // Pulse rate
  'rr' | // Respiration rate
  'ce' | // Chest expansion
  'p' | // Posture
  'af2' | // Activity level
  'spo2'; // Blood oxygen saturation

type MetricToColorMapping = {
  [key in Metric]: string;
};

const metricColors: MetricToColorMapping = {
  bt: '#3EFB09',
  hr: '#FF3100',
  rr: '#FFFFFF',
  ce: '#FF78FF',
  p: '#FB6F09',
  af2: '#FFFF00',
  spo2: '#46FDFC',
};

export function getMetricColor(metric: Metric): string {
  return metricColors[metric] || '#000000';
}
