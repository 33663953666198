import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { createStyles, makeStyles } from "@material-ui/core";
import { of } from "rxjs";
import { delay, map, mergeMap, repeat, take } from "rxjs/operators";
import { caseService } from "../../../../services";
import { toMinutes } from "../../../../lib/utils/to-minutes";
import { Notification } from "../../../../lib/interfaces/notification.interface";
import { ReactComponent as WarningTriangle } from '../../../../theme/warning_triangle.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: '#FFC7C4',
      padding: '16px 24px',
    },
    text: {
      fontSize: 12,
      fontWeight: 500,
      margin: 0
    },
    highlightedText: {
      color: '#2CABE1',
      textDecoration: 'none',
      cursor: 'pointer'
    },
    textBlock: {
      marginBottom: 5,
      "&:last-child": {
        marginBottom: 0
      }
    }
  })
);

const PatientCaseAlert: FunctionComponent = memo(() => {
  const styles = useStyles();
  const [showMessage, setShowMessage] = useState<Notification[] | []>([]);

  useEffect(() => {
    const sub = of({}).pipe(
      mergeMap(() => caseService.getRecallMessage().pipe(
        take(1),
        map((notification: Notification[]) => {
          setShowMessage(notification);
        }),
      )),
      delay(toMinutes(1)),
      repeat(),
    ).subscribe();

    return () => sub.unsubscribe();
  }, []);

  const renderMessage: any = () => {
    return (showMessage as Notification[]).map((item, index) => {
      //this is need for current version of typescript(bug in ts), when you update ts, showMessage as Notification[] should be replaced to showMessage
      if (item.message.includes('TEKTELIC Support')) {
        const wordSplit = item.message.split('TEKTELIC Support');
        return (
          <div className={styles.textBlock} key={index}>
            <p className={styles.text}>{wordSplit?.[0] ?? ''}<a href={'https://support.tektelic.com/portal/en/signin'}
                                                        target={'_blank'}
                                                        rel="noopener noreferrer"
                                                        className={styles.highlightedText}>TEKTELIC Support</a>{wordSplit?.[1] ?? ''}</p>
          </div>
        )
      } else {
        return (
          <div className={styles.textBlock} key={index}>
            <p className={styles.text}>{item.message}</p>
          </div>
        )
      }
    })
  };

  return (
    showMessage.length > 0 ? <div className={styles.container}>
      <WarningTriangle style={{ width: 43, height: 41, marginRight: 10 }}/>
      <div>
        {renderMessage()}
      </div>
    </div> : null
  )
});

export default PatientCaseAlert;