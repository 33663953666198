import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Case } from '../../../../lib/interfaces/case.interface';
import { colors } from '../../../../theme/colors';
import { ButtonBase, InformationItem } from '../../../../lib/components';
import GetAppIcon from '@material-ui/icons/GetApp';
import { dateTimeFormat } from '../../../../lib/utils/format-date';
// import { CHEST_SIZE } from '../../../../lib/constants/chest-size.constant';
import DeletePatientButton from '../DeletePatientButton';
import EditPatientButton from '../EditPatientButton';
import csvService from '../../../../services/csvService';
import { downloadCSV } from '../../../../lib/utils/download-csv';
import { TFunction } from 'i18next';
import usePatientCaseLoader from '../../../../hooks/usePatientCaseLoader';
import CircularProgressWithLabel from '../../../../lib/components/CircularProgressWithLabel';
import { LOADERT_CSV_MAIN } from '../../../../lib/constants/loader-name.constant';
import moment from "moment";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      paddingTop: 14,
      paddingLeft: 24,
      paddingRight: 24,
      display: 'flex',
      backgroundColor: colors.white
    },
    info: {
      display: 'flex',
      width: '90%',
      flexWrap: 'wrap'
    },
    actions: {
      display: 'flex',
      width: '10%',
      alignItems: 'flex-start',
      justifyContent: 'flex-end'
    },
    progress: {
      padding: 5,
      marginLeft: 10,
    },
    actionButton: {
      padding: 5,
      borderRadius: '50%',
      marginLeft: 10, 
    }
  })
);

interface Props {
  patientCase: Case;
  canShowButton: boolean;
}

const downloadPatientCSV = (patientCase: Case, t: TFunction) => () => {
  const { patientId } = patientCase;
  csvService.getCaseData(patientCase, t).subscribe({
    next: (data: any[]) => downloadCSV(`Export of patient ${patientId}`, data),
    complete: csvService.resetLoader
  })
}

const DetailsInfo: FunctionComponent<Props> = memo(({ patientCase, canShowButton }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const csvLoader = usePatientCaseLoader();
  const patientName = `${patientCase.patientFirstName} ${patientCase.patientLastName}`;

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <InformationItem 
          labelText={t('label.patientsName')}
          dataText={patientName}
        />

        <InformationItem
          labelText={t('label.patientsMedicalId')}
          dataText={patientCase.patientId}
        />

        <InformationItem
          labelText={t('label.sex')}
          dataText={patientCase.sex ? t(`sex.${patientCase.sex}`) : '-'}
        />

        <InformationItem
          labelText={t('label.age')}
          dataText={patientCase.age ? `${patientCase.age}` : '-'}
        />

        {/*<InformationItem
          labelText={t('label.chestSize')}
          dataText={`${patientCase.chestSize || CHEST_SIZE} cm`}
        />*/}

        <InformationItem
          labelText={t('label.dateCreated')}
          dataText={dateTimeFormat(patientCase ? patientCase.initiationDate : null)}
        />

        <InformationItem
          labelText={t('footer.sensorUpdate')}
          dataText={dateTimeFormat(patientCase ? patientCase.lastMeasurementDate : null)}
        />

        <InformationItem
          labelText={t('label.currenTime')}
          dataText={moment().format('HH:mm')}
        />

      </div>
      <div className={styles.actions}>

        {
          canShowButton &&
          <div className={styles.actionButton}>
            <EditPatientButton patientCase={patientCase} />
          </div>
        }

        {
          csvLoader && csvLoader.name === patientCase.patientId ?
            <div className={styles.progress}>
              <CircularProgressWithLabel size={40} value={Number(csvLoader.value)} />
            </div>
          :
            <ButtonBase className={styles.actionButton} 
              onClick={
                csvLoader && csvLoader.name === LOADERT_CSV_MAIN ? 
                  () => {} 
                :
                 downloadPatientCSV(patientCase, t)
              }
            >
              <GetAppIcon />
            </ButtonBase>
        }
        
        {
          canShowButton && 
          <div className={styles.actionButton}>
            <DeletePatientButton patientId={patientCase.patientId} />
          </div>
        }
      </div>
    </div>
  );
});

export default DetailsInfo;
