import React, {memo, FunctionComponent, useState, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { CommonHeader } from '../../lib/components';
import DetailsInfo from './components/DetailsInfo';
import DetailsData from './components/DetailsData';
import DetailsFooter from './components/DetailsFooter';
import { colors } from '../../theme/colors';
import { useCurrentCase, useUserInfo } from '../../hooks';
import DetailsEmpty from './components/DetailsEmpty';
import { buttonPermissions } from '../../lib/utils/button-permissions';
import History from '../History';
import { MeasurementKeys } from '../../lib/interfaces/measurement-keys.type';
import moment from "moment";
import {DATE_FORMAT} from "../../lib/constants/date-format.constant";
import { caseService } from "../../services";
import { map } from "rxjs/operators";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column',
      position: 'relative'
    },
    main: {
      flex: 1,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      backgroundColor: colors.backgroundBlue,
      overflowY: 'auto'
    },
    inner: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      overflowY: 'auto',
      backgroundColor: colors.exhibitionBackgroundBlack
    }
  })
);

interface Props {
  patientId: string|null;
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
}

const Details: FunctionComponent<Props> = memo(({
  patientId, isFullScreen, setIsFullScreen
}) => {
  const styles = useStyles();
  let patientCase = useCurrentCase(patientId);
  const [chosen, setOutterChosen] = useState<MeasurementKeys>("bt");
  const user = useUserInfo();
  const canShowButton = buttonPermissions(user, patientCase);
  const [filter, setFilter] = useState(moment.utc().subtract(1, 'day').format(DATE_FORMAT));
  const [chosenElement, setChosenElement] = useState(0);
  const [graphClick, setGraphClick] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [lastHour, setLastHour] = useState(true);

  useEffect(() => {
    setOutterChosen("bt");
  }, [patientId]);

  useEffect(() => {
    caseService.cases.pipe(
      map(cases => {
        const foundCase = (cases || []).find(patientCase => patientCase.patientId === patientId);
        return foundCase || null
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })).subscribe(patientInfo => patientCase = patientInfo);

  }, [chosen])

  return (
    <div className={styles.container}>
      {!isFullScreen && <CommonHeader title={'details'} />}

      { patientCase ?
          <div className={styles.main}>
            <div className={styles.inner}>
              {!isFullScreen && <DetailsInfo patientCase={patientCase} canShowButton={canShowButton} />}
              <DetailsData patientCase={patientCase} setOutterChosen={setOutterChosen} chosen={chosen} isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen}/>
              <History
                patientCase={patientCase}
                fieldName={chosen}
                filter={filter}
                chosenElement={chosenElement}
                setGraphClick={setGraphClick}
                setButtonDisable={setButtonDisable}
                lastHour={lastHour}
              />
            </div>
            {!isFullScreen && <DetailsFooter
              patientCase={patientCase}
              setFilter={setFilter}
              setChosenElement={setChosenElement}
              filter={filter}
              graphClick={graphClick}
              buttonDisable={buttonDisable}
              setGraphClick={setGraphClick}
              setButtonDisable={setButtonDisable}
              setLastHour={setLastHour}
            />}
          </div>
        :
          <DetailsEmpty />
      }
    </div>
  );
});

export default Details;
