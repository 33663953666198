import { User } from './../../lib/interfaces/user.interface';
import { useState, useEffect } from 'react';
import { authService } from '../../services';

const useUserInfo = () => {
  const [user, setUser] = useState<User|null>(null);

  useEffect(() => {
    const sub = authService.getUserInfo().subscribe(setUser);
    
    return () => sub.unsubscribe();
  }, []);

  return user;
};

export default useUserInfo;
