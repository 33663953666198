import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Case } from '../../interfaces/case.interface';
import { object as yupObject, string as yupString } from 'yup';
import { TFunction } from 'i18next';
import { Formik, FormikProps } from 'formik';
import TextField from '../TextField';
import { Button } from '..';
import { formSubmission } from '../../utils/form-submission';
import { PatientForm } from '../../interfaces/patient-form.interface';
import { CHEST_SIZE } from '../../constants/chest-size.constant';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center'
    },
    item: {
      marginBottom: 24 
    }
  })
);

interface Props {
  patientCase: Case;
  submitForm: (values: PatientForm, event: () => void) => void;
  onCancel: () => void;
}

const validationSchema = (t: TFunction) => yupObject().shape({
  firstName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.firstNameRequired')),
  lastName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.lastNameRequired')),
  chestSize: yupString()
    .required(t('validation.chestSizeRequired')),
});

const EditPatientForm: FunctionComponent<Props> = memo(({ 
  patientCase, submitForm, onCancel
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { patientFirstName, patientLastName, chestSize } = patientCase;

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid,
    setSubmitting
  }: FormikProps<PatientForm>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);
    const keyboardSubmit = () => {
      if (isValid) {
        setSubmitting(true);
        submitForm(values, () => setSubmitting(false));
      }
    };

    return (
      <>
        <form className={styles.form} onSubmit={keyboardSubmit}>
          <div className={styles.item}>
            <TextField
              label={t('placeholder.firstName')}
              onChange={onChangeText('firstName')}
              onBlur={onBlur('firstName')}
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.firstName && errors.firstName) ? errors.firstName : undefined}
            />
          </div>

          <div className={styles.item}>
            <TextField
              label={t('placeholder.lastName')}
              onChange={onChangeText('lastName')}
              onBlur={onBlur('lastName')}
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.lastName && errors.lastName) ? errors.lastName : undefined}
            />
          </div>

          <div className={styles.item}>
            <TextField
              label={t('placeholder.chestSize')}
              onChange={onChangeText('chestSize')}
              onBlur={onBlur('chestSize')}
              value={String(values.chestSize)}
              error={Boolean(touched.chestSize && errors.chestSize)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.chestSize && errors.chestSize) ? errors.chestSize : undefined}
            />
          </div>
        </form>
        
        <div className={styles.item}>
          <Button
            variant="text"
            onClick={onCancel}
          >
            { t('button.cancel') }
          </Button>

          <Button
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            onClick={handleSubmit}
          >
            { t('button.submit') }
          </Button>
        </div>
      </>
  )};

  return (
    <Formik
      initialValues={{ firstName: patientFirstName, lastName: patientLastName, chestSize: chestSize || CHEST_SIZE }}
      onSubmit={formSubmission<PatientForm>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default EditPatientForm;
