import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { generateHeadCells } from '../../../../lib/utils/generate-head-cells';
import { Table } from '../../../../lib/components';
import { CaseViewModel } from '../../../../lib/models/case-view.model';
import { modifyTableData } from '../../../../lib/utils/modify-table-data';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../../../lib/utils/format-date';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowY: 'auto'
    }
  })
);

interface Props {
  patients: CaseViewModel[];
  setPatientId: (value: string) => void;
}

const PatientCaseBody: FunctionComponent<Props> = memo(({
  patients, setPatientId
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const headCells = generateHeadCells(patients[0], false, ['patientId', 'deviceId', 'patientFirstName', 'patientLastName']);
  const bodyCells = patients.map(caseView => {
    return {
      id: caseView.patientId,
      patientName: modifyTableData(caseView.patientName),
      initiationDate: modifyTableData(caseView.initiationDate, '', dateTimeFormat(caseView.initiationDate)),
      sex: modifyTableData(caseView.sex ? t(`sex.${caseView.sex}`) : '-'),
      age: modifyTableData(caseView.age ? String(caseView.age) : '-'),
      deviceType: modifyTableData(caseView.deviceType ? t(`deviceType.${caseView.deviceType}`) : '-'),
    };
  });

  return (
    <div className={styles.container}>
      <Table
        orderByInit={'initiationDate'}
        data={bodyCells}
        headCells={headCells}
        omitSortKeys={[]}
        onSelect={setPatientId}
        disablePagination
      />
    </div>
  );
});

export default PatientCaseBody;
