import React, {memo, FunctionComponent, useState, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import PatientCaseHeader from './components/PatientCaseHeader';
import PatientCaseBody from './components/PatientCaseBody';
import CreateCaseFlow from './components/CreateCaseFlow';
// import { useCaseList } from '../../hooks';
import PatientSearch from './components/PatientSearch';
import { matchName } from '../../lib/utils/match-name';
import { CircularProgress } from '../../lib/components';
import PatientsEmpty from './components/PatientsEmpty';
import { CaseViewModel } from '../../lib/models/case-view.model';
import { colors } from '../../theme/colors';
import PatientCaseAlert from "./components/PatientCaseAlert";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexFlow: 'column',
      maxWidth: 525,
      width: '100%',
      paddingBotton: 40,
      position: 'relative',
      backgroundColor: colors.white,
      borderRight: '1px solid rgba(158, 162, 165, 0.5)'
    },
    patientContainer: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    }
  })
);

interface Props {
  setPatientId: (value: string) => void;
  patientCases: CaseViewModel[]|null;
}

const PatientCase: FunctionComponent<Props> = memo(({ setPatientId, patientCases }) => {
  const styles = useStyles();

  const [patientsWrap, setPatientsWrap] = useState(patientCases);

  useEffect(() => {
    setPatientsWrap(patientCases);
  }, [patientCases]);

  const onSearch = (value: string) => {
    const filteredCases = patientCases && patientCases.filter((patientСase: CaseViewModel) => {
      if (patientСase) {
        const firstNameMatch = matchName(patientСase.patientFirstName, value);
        const lastNameMatch = matchName(patientСase.patientLastName, value);
        return firstNameMatch || lastNameMatch;
      }
      return false;
    });
    setPatientsWrap(filteredCases);
  }

  return (
    <div className={styles.container}>
      <PatientCaseHeader />
      <PatientCaseAlert />
      <PatientSearch onSearch={onSearch} />
        {
          !patientCases ?
            <CircularProgress />
          :
            <>
              {
                (patientsWrap && patientsWrap.length) ?
                  <PatientCaseBody patients={patientsWrap} setPatientId={setPatientId} />
                :
                  <PatientsEmpty />
              }
            </>
        }
        <CreateCaseFlow />
    </div>
  );
});

export default PatientCase;
