import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NewPatient } from '../../../../lib/interfaces/new-patient-form.interface';
import { TFunction } from 'i18next';
import { object as yupObject, string as yupString, number as yupNumber } from 'yup';
import { Button, TextField, Typography } from '../../../../lib/components';
import { CHEST_SIZE } from '../../../../lib/constants/chest-size.constant';
import { formSubmission } from '../../../../lib/utils/form-submission';
import { Formik, FormikProps } from 'formik';
import { Select } from '../../../../lib/components';
import SelectDeviceComponent from '../SelectDeviceComponent';

const DEFAULT_AGE = 18;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 380
    },
    item: {
      marginBottom: 24 
    },
    input: {
      width: '100%',
    },
    title: {
      paddingTop: 40,
      paddingBottom: 16,
      fontSize: 18,
      fontWeight: 500
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16
    },
    button: {
      width: 160
    }
  })
);

interface Props {
  submitForm: (values: NewPatient, event: () => void) => void;
}

const validationSchema = (t: TFunction) => yupObject().shape({
  firstName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.firstNameRequired')),
  lastName: yupString()
    .min(2, t('validation.minLength', {num: 2}))
    .required(t('validation.lastNameRequired')),
  patientId: yupString()
    .required(t('validation.patientIdRequired'))
    .matches(
      /^[a-zA-Z0-9]*$/, //todo: extract to constant
      t('validation.onlyLettersAndNumbersAreAllowed')
    ),
  sex: yupString()
    .required(t('validation.sexRequired')),
  age: yupNumber()
    .required(t('validation.ageRequired'))
    .min(0)
    .max(150),
  chestSize: yupString()
    .required(t('validation.chestSizeRequired')),
  deviceId: yupString()
    .required(t('validation.deviceNameRequired'))
});

const CreatePatientForm: FunctionComponent<Props> = memo(({ submitForm }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    isSubmitting,
    isValid,
    dirty,
    setSubmitting
  }: FormikProps<NewPatient>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string) => (value: string) => setFieldValue(name, value);
    const keyboardSubmit = () => {
      if (isValid) {
        setSubmitting(true);
        submitForm(values, () => setSubmitting(false));
      }
    };
    const sexList = ['male', 'female', 'unknown'].map(sex => ({value: sex, text: t(`sex.${sex}`)}));

    return (
      <>
        <form className={styles.container} onSubmit={keyboardSubmit}>

        {/* For future use to prevent navigation */}
        {/* <Prompt
            when={dirty}
            message={location =>
              `Are you sure you want to go to ${location.pathname}`
            }
          /> */}

          <Typography className={styles.title}>{t('header.givePatientInformation')}</Typography>

          <div className={styles.item}>
            <TextField
              className={styles.input}
              label={t('placeholder.firstName')}
              onChange={onChangeText('firstName')}
              onBlur={onBlur('firstName')}
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.firstName && errors.firstName) ? errors.firstName : undefined}
            />
          </div>

          <div className={styles.item}>
            <TextField
              className={styles.input}
              label={t('placeholder.lastName')}
              onChange={onChangeText('lastName')}
              onBlur={onBlur('lastName')}
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.lastName && errors.lastName) ? errors.lastName : undefined}
            />
          </div>

          <div className={styles.item}>
            <TextField
              className={styles.input}
              label={t('placeholder.patientId')}
              onChange={onChangeText('patientId')}
              onBlur={onBlur('patientId')}
              value={values.patientId}
              error={Boolean(touched.patientId && errors.patientId)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.patientId && errors.patientId) ? errors.patientId : undefined}
            />
          </div>

          <div className={styles.item}>
            <div className={styles.container}>
              <Select
                elements={sexList}
                placeholder={t('placeholder.sex')}
                value={String(values.sex)}
                onChange={onChangeText('sex')}
                required
                disabled={isSubmitting}
                error={Boolean(touched.sex && errors.sex)}
                helperText={(touched.sex && errors.sex) ? errors.sex : undefined}
              />
            </div>
          </div>

          <div className={styles.item}>
            <TextField
              className={styles.input}
              label={t('placeholder.age')}
              onChange={onChangeText('age')}
              type={'number'}
              onBlur={onBlur('age')}
              value={String(values.age)}
              error={Boolean(touched.age && errors.age)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.age && errors.age) ? errors.age : undefined}
            />
          </div>

          <div className={styles.item}>
            <TextField
              className={styles.input}
              label={t('placeholder.chestSize')}
              onChange={onChangeText('chestSize')}
              onBlur={onBlur('chestSize')}
              value={String(values.chestSize)}
              error={Boolean(touched.chestSize && errors.chestSize)}
              disabled={isSubmitting}
              required={true}
              helperText={(touched.chestSize && errors.chestSize) ? errors.chestSize : undefined}
            />
          </div>

          <Typography className={styles.title}>{t('header.selectDevice')}</Typography>
          <div className={styles.item}>
            <SelectDeviceComponent 
              onChange={onChangeText('deviceId')}
              required
              disabled={isSubmitting}
              error={Boolean(touched.deviceId && errors.deviceId)}
              helperText={(touched.deviceId && errors.deviceId) ? errors.deviceId : undefined}
            />
          </div>

          <div className={styles.buttonWrapper}>
            <Button
              rootClass={styles.button}
              loading={isSubmitting}
              // disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            >
              { t('button.submit') }
            </Button>
          </div>
        </form>
        
      </>
  )};

  return (
    <Formik
      initialValues={{ 
        firstName: '', lastName: '',
        sex: '',
        age: String(DEFAULT_AGE),
        chestSize: String(CHEST_SIZE), patientId: '',
        deviceId: ''
      }}
      onSubmit={formSubmission<NewPatient>(submitForm)}
      validationSchema={validationSchema(t)}
    >
      {renderForm}
    </Formik>
  );
});

export default CreatePatientForm;
