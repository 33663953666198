import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';
import CreatePatientHeader from './components/CreatePatientHeader';
import CreatePatientForm from './components/CreatePatientForm';
import { NewPatient } from '../../lib/interfaces/new-patient-form.interface';
import { caseService, messageService } from '../../services';
import { useHistory } from 'react-router-dom';
import { NavRoute } from '../../lib/constants/nav-route.constant';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      width: '100%',
      backgroundColor: colors.backgroundBlue,
      paddingTop: 80,
      paddingLeft: 280
    },
    body: {
      paddingLeft: 52
    }
  })
);

const CreatePatientCase: FunctionComponent = memo(() => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const submit = (values: NewPatient, event: () => void) => {
    caseService.createCaseFlow(values).subscribe({
      next: () => {messageService.addMessage(t('text.patientWasCreated'))},
      complete: () => {history.replace(NavRoute.MAIN.INIT)}
    })
  }

  return (
    <div className={styles.container}>
      <CreatePatientHeader />
      <div className={styles.body}>
        <CreatePatientForm submitForm={submit} />
      </div>
    </div>
  );
});

export default CreatePatientCase;
