import React, {memo, FunctionComponent} from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Paper } from '../../../../lib/components';
import EmptyMetrics from '../../../../lib/components/EmptyMetrics';
import DisplayHistoryChart from '../DisplayHistoryChart';
import { MeasurementKeys } from '../../../../lib/interfaces/measurement-keys.type';
import { MetricsView } from '../../../../lib/interfaces/metric-history.interface';
// import { useLoader } from '../../../../hooks';
// import { LoaderName } from '../../../../lib/constants/loader-name.constant';
// import Skeleton from '@material-ui/lab/Skeleton';
import {colors} from "../../../../theme/colors";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      borderRadius: 8,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: colors.exhibitionTileBackgroundBlack
    }
  })
);

interface Props {
  metrics: MetricsView[]|null,
  hrMetrics: MetricsView[]|null,
  keyName: MeasurementKeys|null,
  date?: string,
  hrField: boolean,
  setGraphClick: (value: boolean) => void;
  setButtonDisable: (value: boolean) => void;
  setTableView: (value: boolean) => void;
  tableView: boolean;
  lastHour: boolean;
}

const HistoryChart: FunctionComponent<Props> = memo(({
  metrics, keyName, hrMetrics, hrField, setGraphClick, setButtonDisable, setTableView, tableView, lastHour
}) => {
  const styles = useStyles();
  // const loader = useLoader();

  return (
    <Paper className={styles.container}>
      {
        keyName && metrics && hrMetrics && metrics.length ?
          <>
            <DisplayHistoryChart
              metrics={metrics}
              keyName={keyName}
              // date={date}
              hrMetrics={hrMetrics}
              hrField={hrField}
              setGraphClick={setGraphClick}
              setButtonDisable={setButtonDisable}
              setTableView={setTableView}
              tableView={tableView}
              lastHour={lastHour}
            />
          </>
          :
          <EmptyMetrics />
      }
    </Paper>
  );
});

export default HistoryChart;
